import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import moment from 'moment-timezone';

@Component({
  selector: 'app-alert-date-picker',
  templateUrl: './alert-date-picker.component.html',
  styleUrls: ['./alert-date-picker.component.scss'],
})
export class AlertDatePickerComponent implements OnInit {
  public formattedDateString: any;
  public today = new Date();
  @Input() feedDate = new Date();
  @Input() presentation = 'date-time';
  @Input() formatOfFomattedString = 'DD MMM YYYY, h:mm A';
  @Input() options = {
    classes: '',
    lines: '',
    labelPosition: "'floating'",
  };
  public dateValue = moment(
    new Date(this.today.getTime() - this.today.getTimezoneOffset() * 60000)
  ).toISOString();
  @Input() label = 'Date';
  @Input() hash: any;
  @Output() onDateChange = new EventEmitter<any>();
  @Input() min: Date;
  constructor() {}

  ngOnInit() {
    if (this.label == 'on_date') {
      this.label = '';
      if (this.feedDate == null) {
        this.today = new Date();
        this.today.setHours(this.today.getHours() + 24);
        this.today.setMinutes(this.today.getMinutes() + 15);
        const date = new Date(this.today);

        // Format to desired output with timezone offset (UTC+5:30)
        const formattedDate = date.toLocaleString('en-GB', {
          timeZone: 'Asia/Kolkata',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        });

        const [datePart, timePart] = formattedDate.split(', ');
        const [day, month, year] = datePart.split('/');
        const [hour, minute, second] = timePart.split(':');

        // Format to ISO 8601
        const isoString = `${year}-${month}-${day}T${hour}:${minute}:${second}+05:30`;
        this.onDateChange.emit(isoString);
      }
    }
    this.formattedDateString = moment(this.today).format(this.formatOfFomattedString);
  }

  ngOnChanges(changes) {
    if (
      changes.feedDate &&
      changes.feedDate?.currentValue &&
      changes.feedDate?.currentValue !== this.dateValue
    ) {
      this.feedDate = changes.feedDate?.currentValue;
      this.today = new Date(changes.feedDate?.currentValue);
      this.dateValue = moment(
        new Date(this.today.getTime() - this.today.getTimezoneOffset() * 60000)
      ).toISOString();
      this.formattedDateString = moment(this.today).format(this.formatOfFomattedString);
    }
  }

  dateChanged(value) {
    this.dateValue = value;
    this.formattedDateString = moment(value).format(this.formatOfFomattedString);
    this.onDateChange.emit(value);
  }
}
