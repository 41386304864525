import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { Page } from 'src/app/models/page';
import { ChatService } from 'src/app/services/chat.service';
import { LoaderService } from 'src/app/services/loader.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';
import { TradeToolsService } from 'src/app/services/trade-tools-setting';

@Component({
  selector: 'app-trade-trackers',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class TradeTrackersProductsComponent implements OnInit {
  public role;
  public tag = [];

  productList: any;
  [x: string]: any;
  tool: any;
  public toolTitle: string;
  public isToolAccess = false;
  public lockedPageContent: null;
  public toolIsReadOnlyForUser = false;
  public subscriptions = [];
  public page = new Page();
  public toolReadOnlyMessage = '';
  public threadChat: any[] = [];
  public connected = false;
  isInsider: boolean = false;
  public roomJoined = false;
  isFullAccess: boolean = false;
  isLiteAccess: boolean = false;
  isRegularAccess: boolean = false;
  constructor(
    public storageServ: StorageService,
    private toolsService: ToolServiceService,
    public loaderService: LoaderService,
    private route: ActivatedRoute,
    private tradeToolsService: TradeToolsService
  ) {
    this.lockedPageContent = null;
    this.role = this.storageServ.get('role');
    this.tag = this.storageServ.get('tags') ? JSON.parse(this.storageServ.get('tags')) : [];

    this.activeToolSlug = this.route.snapshot.params.toolSlug;
    if (this.activeToolSlug) {
      this.tradeToolsService
        .getToolDetails(this.activeToolSlug, 'true')
        .subscribe((toolResponse: any) => {
          const { tools: toolDetails = null } = toolResponse.data;
          if (toolDetails) {
            this.storageServ.set('toolId', JSON.stringify(toolDetails));
            this.handletoolInit(toolDetails);
          }
        });
    }
  }

  ngOnInit() {
    let access = localStorage.getItem('access');
    this.isFullAccess = access === 'Full_Access';
    this.isLiteAccess = access === 'Lite_Access';
    this.isRegularAccess = access === 'Regular_Access';
    this.getProductList();
  }

  getProductList() {
    this.toolsService.getProductList().subscribe((response: any) => {
      response.data.trackerProducts.forEach((x) => {
        // if (x.slug == 'h37CPml') {
        //   x.status = 'active';
        //   x.productImage = 'assets/icon/master-income-trade-tracker.png';
        // }
      });
      this.productList = response.data;
    });
  }

  handletoolInit(tool) {
    this.tool = tool;
    if (this.tool && this.role) {
      const { readonly = false, postAllowedRoles = [], status = null, tags } = this.tool;
      if (status && status === 'locked' && !readonly) {
        const allIdsExist = tags.some((id) => this.tag.includes(Number(id)));
        if (allIdsExist) {
          this.isToolAccess = true;
          this.lockedPageContent = null;
        } else {
          this.loaderService.HideLoader();
          this.toolTitle = tool.title;
          this.lockedPageContent = tool.lockedPageContent;
        }
      } else if (status && status === 'published') {
        this.isToolAccess = true;
        this.lockedPageContent = null;
      } else {
        if (readonly) {
          this.toolIsReadOnlyForUser = !postAllowedRoles.includes(this.userRole);
          if (this.toolIsReadOnlyForUser) {
            this.toolReadOnlyMessage =
              'The channel has been opened with specific levels of access, you are not permitted to post. For more information, please email thecrew@tradersedgenetwork.com.';
          }
        }
      }
    }
  }
}
