<ion-content class="ion-padding" appScrollbar>
  <div class="general-main" *ngIf="!isToolAccess">
    <div class="no-data-container">
      <div class="no-data">
        <img src="/assets/icon/locked.png" alt="" />
        <p *ngIf="!isToolAccess && !lockedPageContent">
          Please be patience, reviewing tool access.
        </p>
        <p
          *ngIf="!isToolAccess && lockedPageContent"
          [innerHTML]="lockedPageContent | keepHtml"
        ></p>
      </div>
    </div>
  </div>
  <div *ngIf="isToolAccess && !lockedPageContent">
    <ol class="cd-breadcrumb custom-separator custom-icons">
      <li><a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools</a></li>
      <li>
        <a routerLink="/{{ role }}/tools/setup-scanner/{{ activeToolSlug }}">Scanners</a>
      </li>
    </ol>

    <div *ngIf="!showDatatable">
      <div class="page-heading">
        <h2>Preset Scanners</h2>
      </div>

      <ng-container slides-per-view="1" speed="500" pagination="true" class="step-scanner">
        <ion-slide class="step-scanner staic-data-wrap">
          <ion-grid class="icon-grid-block">
            <ion-row>
              <ion-col size="4" *ngFor="let row of staticData">
                <ion-card>
                  <ion-card-header class="setup-scanner-header">
                    <img class="setup-img" [src]="row.image" />
                    <div class="custom-css">
                      <div
                        class="custom-css-content"
                        [ngClass]="{ 'no-data-preview': !row.description }"
                      >
                        <span class="primium-watchlist setupname-css">{{ row.name }}</span>
                        <p class="font-css">{{ row.description }}</p>
                        <div *ngIf="!row.description" class="Under-construction">
                          <div>
                            <ion-icon name="construct-outline"></ion-icon>
                            <h1>Under construction</h1>
                          </div>
                        </div>
                      </div>

                      <span
                        *ngIf="row.description"
                        class="primium-watchList font-css1"
                        routerLink="/{{ role }}/tools/setup-scanner/{{ activeToolSlug }}/product/{{
                          row._id
                        }}"
                      >
                        View
                      </span>
                    </div>
                  </ion-card-header>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-slide>
      </ng-container>
    </div>
  </div>

  <div *ngIf="!showDatatable">
    <div class="page-heading">
      <h2>My Scanners</h2>
      <div class="actions">
        <div class="crypto-details-button">
          <ion-button
            class="color-css1 create-own"
            fill="outline"
            (click)="createEditSetupScanner('add')"
          >
            <ion-icon name="add-outline" fill="outline"></ion-icon> Create Custom Scanner
          </ion-button>
        </div>
      </div>
    </div>
    <div class="no-any-setup" *ngIf="isNoData">You haven’t created any scanners yet.</div>

    <ng-container slides-per-view="1" speed="500" pagination="true" class="step-scanner">
      <ion-slide class="step-scanner">
        <ion-grid class="icon-grid-block">
          <ion-row>
            <ion-col size="4" *ngFor="let row of setupScannerList">
              <ion-card class="setup-card">
                <ion-card-header class="setup-scanner-header">
                  <div class="color-block-name" [style.backgroundColor]="row.color">
                    <span [style.color]="row.colorspan">{{ row.firstLetter }}</span>
                  </div>
                  <!-- <img class="setup-img" [src]="row.coverURL" /> -->
                  <div class="custom-css">
                    <div class="custom-css-content">
                      <span class="primium-watchlist setupname-css">{{ row.name }}</span>
                      <!-- <p class="font-css">{{ row.description }}</p> -->
                    </div>
                    <span
                      *ngIf="row.name"
                      class="primium-watchList font-css1"
                      routerLink="/{{ role }}/tools/setup-scanner/{{ activeToolSlug }}/product/{{
                        row._id
                      }}"
                    >
                      View
                    </span>
                  </div>
                </ion-card-header>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-slide>
    </ng-container>
  </div>
</ion-content>
