import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { LoaderService } from 'src/app/services/loader.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';
import { TradeToolsService } from 'src/app/services/trade-tools-setting';
import { WatchListCreateAddModalComponent } from '../watchlist-create-add-modal.component';
import { Page } from 'src/app/models/page';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AlertDeleteModalComponent } from '../../alerts-details/alert-delete-modal/alert-delete-modal.component';
import { StockDetailWatchListCreateModalComponent } from '../stock-detail-watchlist-create-modal.component';
import { TradeTrackersInsidersComponent } from '../../trade-trackers/trade-trackers-insiders/trade-trackers-insiders.component';
@Component({
  selector: 'app-tool-dashboard-symbol-details',
  templateUrl: './tool-dashboard-symbol-details.component.html',
  styleUrls: ['./tool-dashboard-symbol-details.component.scss'],
})
export class ToolDashboardSymbolDetailsComponent implements OnInit {
  @ViewChild('hotlistsymbol', { static: false }) hotlistsymbol?: ElementRef;
  @Input() positionProps: any;
  @Input() toolNotesData: any;
  iframeSrc!: SafeResourceUrl;
  role: any;
  rows = [];
  showForm = false;
  public tag = [];
  public page = new Page();
  listOfLookup: any[] = [];
  public searchText: string = null;
  lookupListVisibility: boolean = false;
  showDetails: boolean = false;
  searchResult: any[];
  selectedStockDetails: any;
  public selectedNews: [] = [];
  public selectedNotes: [] = [];
  public rowsA = [
    { id: 1, price: 240, alert: 'Price moves above $240' },
    { id: 2, price: 200, alert: 'Price moves below $200' },
  ];

  public segment: string = '';
  public arr = new Array(25);
  public isToolAccess = false;
  isLoading = true;
  public lockedPageContent: null;
  slug: string;
  isDropdownOpen = false;
  [x: string]: any;
  alertData: any;
  highlightedIndex: number = -1;
  public interval: any;
  timeOutPageTime = 0;
  editSlug: string;
  mode: 'edit' | 'create';
  symbol: any;
  userId: any;
  watchList: any;
  context: 'dashboard' | 'watchlist' | 'setup-scanner' = 'dashboard';
  fromWatchlist: boolean = false;
  watchlistCount: number;
  notesCount: number;
  alertCount: number;

  selectedLookup: {
    name: string;
    symbol: string;
    exch: string;
  } = {
    name: '',
    symbol: '',
    exch: '',
  };
  buttonStateGroup = {
    is_watch_list: false,
    title: 'Add To Watchlist',
    icon: 'add-outline',
    isButtonActive: false,
    color: 'dark',
  };
  AlertRow: AlertRow = {
    triggerPrice: '',
    triggerDate: new Date(),
  };
  defaultView: string;
  defaultAlertType: string = 'stockInfoAlert';
  tradeHistoryList: any = [];
  stockPriceAlert: any = [];
  constructor(
    public storageServ: StorageService,
    private toolsApi: ToolServiceService,
    private _renderer2: Renderer2,
    private cdr: ChangeDetectorRef,
    private toastServ: ToastService,
    public modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private menuService: commonRightMenuService,
    private tradeToolsService: TradeToolsService,
    public loaderService: LoaderService,
    private location: Location,
    private sanitizer: DomSanitizer,
    public alertController: AlertController,
    private toolService: ToolServiceService
  ) {
    this.lockedPageContent = null;
    this.role = this.storageServ.get('role');
    this.tag = this.storageServ.get('tags') ? JSON.parse(this.storageServ.get('tags')) : [];
    this.activeToolSlug = this.activatedRoute?.snapshot.params.id;
    this.symbol = this.activatedRoute?.snapshot.params.symbol;
    if (this.activeToolSlug) {
      this.tradeToolsService
        .getToolDetails(this.activeToolSlug, 'true')
        .subscribe((toolResponse: any) => {
          const { tools: toolDetails = null } = toolResponse.data;
          if (toolDetails) {
            this.storageServ.set('toolId', JSON.stringify(toolDetails));
            this.handletoolInit(toolDetails);
          }
        });
    }
  }

  ngOnInit() {
    this.onStockDetails(this.symbol);
    this.rowsA = this.alert;
    this.menuService.getTriggerChildResetAdd().subscribe((data) => {
      this.getSymbolWiseNotes(this.selectedStockDetails.symbol);
    });
    this.menuService.getTriggerChildResetAlert().subscribe((data) => {
      this.AlertRow.triggerPrice = data.triggerPrice
        ? data.triggerPrice
        : this.AlertRow.triggerPrice;
      this.AlertRow.triggerDate = data.triggerDate ? data.triggerDate : this.AlertRow.triggerDate;
      this.getAlertList();
      this.onStockDetails(this.symbol);
    });
    this.isLoading = false;
    this.segment = this.toolsApi.getSegmentValue(); // Retrieve the segment value
    if (this.segment === 'alert') {
      setTimeout(() => {
        this.defaultView = 'alert';
        this.getAlertList();
        this.defaultAlertType = this.toolsApi.getAlertValue();
      }, 1000);
    } else if (this.segment === 'stockInfo') {
      this.defaultView = 'stockInfo';
      this.cdr.detectChanges();
      this.loadSymbolGraph();
    }
  }

  toggleDropdown(event: Event, alertList: any) {
    event.preventDefault();
    event.stopPropagation();
    if (this.selectedRow === alertList) {
      this.isDropdownOpen = !this.isDropdownOpen;
    } else {
      this.isDropdownOpen = true;
      this.selectedRow = alertList;
    }
  }

  toggleButtonColor() {
    let access = localStorage.getItem('access');
    const isFullAccess = access === 'Full_Access';
    const isLiteAccess = access === 'Lite_Access';
    const isRegularAccess = access === 'Regular_Access';
    if (isFullAccess) {
      this.addToWatchlist();
    } else {
      this.onRedirectInsider();
    }
  }

  updateWatchListCount(count: number) {
    this.watchListCount = count;
  }

  goBack() {
    this.location.back();
  }

  openRightMenuAlert(mode, editData?: any) {
    this.isDropdownOpen = !this.isDropdownOpen;
    this.mode = mode;
    const alertData: any = {
      mode,
      componentType: 'alertComponent',
      slug: this.toolsNotesSlug,
      symbol: this.selectedStockDetails.symbol,
      currentStock: this.selectedStockDetails.regularMarketPrice,
      editData: mode === 'edit' ? editData : null,
    };
    if (mode === 'edit') {
      alertData.editData = editData;
    }
    this.menuService.openRightMenu(alertData);
  }
  onAlertCreateSubmit(alertData) {
    let triggerDate = 0;
    let expirationDate = 0;
    let triggerPrice = 0;
    const message = alertData.message;
    const alertType = alertData.type;
    const conditionType = alertData.conditionType;

    // Toggle the alert status
    alertData.status = alertData.status === 'active' ? 'inactive' : 'active';
    const status = alertData.status;
    const slug = alertData.slug;
    const currentDate = new Date();
    const oneMonthAhead = new Date();
    const expirationDateValue = expirationDate;
    oneMonthAhead.setMonth(currentDate.getMonth() + 1);
    this.selectedDateTime = expirationDateValue
      ? expirationDateValue
      : this.dateFormate(currentDate);
    if (alertType === 'on_date') {
      triggerDate = alertData.triggerDate;
    }
    if (alertType === 'stock_price') {
      triggerPrice = alertData.triggerPrice;
      expirationDate = alertData.expirationDate;
    }

    const payload = {
      message: message,
      tickerSlug: this.toolsNotesSlug,
      status: status,
      alertType: alertType,
      conditionType: conditionType,
      ...(alertType === 'on_date' ? { triggerDate: triggerDate } : {}),
      ...(alertType === 'stock_price' ? { triggerPrice: triggerPrice } : {}),
      ...(alertType === 'stock_price' ? { expirationDate: expirationDate } : {}),
    };

    this.toolService.updateAlert(payload, slug).subscribe((res: any) => {
      if (res) {
        this.menuService.setCloseMenuSubject(payload);
        this.showForm = false;
        this.toastServ.presentToast(res?.message, 'success');
        this.getAlertList();
      }
    });
  }

  dateFormate(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${month}/${day}/${year}`;
  }

  openRightMenuNotification() {
    const notificationData = {
      componentType: 'alertNotificationComponent',
      slug: this.toolsNotesSlug,
      symbol: this.selectedStockDetails.symbol,
      name: this.selectedStockDetails.longName,
    };
    this.menuService.openRightMenu(notificationData); // Trigger the menu open event
  }
  handleKeyDown(event: KeyboardEvent) {
    if (this.lookupListVisibility && this.searchResult.length > 0) {
      switch (event.key) {
        case 'ArrowDown':
          if (this.highlightedIndex < this.searchResult.length - 1) {
            this.highlightedIndex++;
          }
          event.preventDefault();
          break;
        case 'ArrowUp':
          if (this.highlightedIndex > 0) {
            this.highlightedIndex--;
          }
          event.preventDefault();
          break;
        case 'Enter':
          if (this.highlightedIndex >= 0) {
            this.addLookup(this.searchResult[this.highlightedIndex].symbol);
          } else if (this.searchResult.length > 0) {
            this.addLookup(this.searchResult[0].symbol);
          }
          event.preventDefault();
          break;
      }
    }
  }

  onListForSymbol(searchText: string, disableHttpLoader = 'true') {
    if (searchText) {
      this.isLoading = true;
      this.toolsApi.getTickerSymbol(searchText, disableHttpLoader).subscribe((response: any) => {
        if (response && response?.data) {
          this.listOfLookup = response?.data;
          // this.listOfLookup = this.listOfLookup.filter((x) => x.type === 'S');
          this.onSearchLookup(searchText);
          this.isLoading = false;
        }
      });
    } else {
      this.listOfLookup = [];
      this.isLoading = false;
    }
  }

  onSearchLookup(event: string) {
    this.lookupListVisibility = true;
    const trimmedSearchValue = event;
    if (trimmedSearchValue.length > 0) {
      const exactMatches = [];
      const otherMatches = [];
      for (const item of this.listOfLookup) {
        if (item.name.includes(item?.subCategory)) {
          item.name = item?.name?.split(item?.subCategory)?.[0].trim();
        }
        const value = `${item.symbol} - ${item.name}`.toLowerCase();
        if (value.startsWith(trimmedSearchValue)) {
          exactMatches.push(item);
        } else if (value.includes(trimmedSearchValue)) {
          otherMatches.push(item);
        }
      }
      this.searchResult = [...exactMatches, ...otherMatches];
      this.highlightedIndex = -1;
    } else {
      this.lookupListVisibility = false;
      this.selectedLookup = {
        symbol: '',
        name: '',
        exch: '',
      };
    }
  }

  onSearchLookupNew(event: CustomEvent) {
    this.lookupListVisibility = true;
    const trimmedSearchValue = event.detail.value.trim().toLowerCase();
    this.onListForSymbol(trimmedSearchValue, 'true');
    this.lookupListVisibility = false;
  }
  addLookup(symbol: string) {
    this.hideLookupList();
    this.onClearSearch();
    this.toolsApi.setContext('dashboard');
    this.router.navigate([
      `/${this.role}/tools/dashboard/${this.activeToolSlug}/stock-info/${symbol}`,
    ]);
  }
  onClickHistory(data: any) {
    this.hideLookupList();
    this.onClearSearch();
    this.toolsApi.setContext('dashboard');
    localStorage.setItem('trackerProductId', data?.trackerProductId);
    this.router.navigate([
      `/${this.role}/tools/trade-trackers/${this.activeToolSlug}/product/${data.tradeTrackerProduct.slug}/ticker/${data.slug}`,
    ]);
  }
  async addToWatchlist() {
    const modal = await this.modalController.create({
      component: StockDetailWatchListCreateModalComponent,
      cssClass: 'watchlist-create-modal',
      componentProps: {
        selectedSymbol: this.symbol,
        selectedIds: this.userId,
      },
    });

    modal.onDidDismiss().then(({ data }) => {
      this.onStockDetails(this.symbol);
      if (data) {
        this.toolsApi.addWatchList(data).subscribe((response: any) => {
          if (response && response.message) {
            this.toastServ.presentToast(response.message, 'success');
          }
        });
        this.onStockDetails(this.symbol);
        this.watchlistCountShow = data.watchListCount;
      }
    });
    return await modal.present();
  }

  removeWatchList() {
    this.toolsApi.removeFromWatchList(this.symbol).subscribe((response: any) => {
      if (response) {
        this.toastServ.presentToast(response.message, 'success');
        this.onStockDetails(this.symbol);
      }
    });
  }
  onClearSearch() {
    this.searchText = null;
  }

  onListForWatchList() {
    this.toolsApi.getForWatchList().subscribe((response: any) => {
      if (response && response?.data) {
        this.watchList = response?.data;
      }
    });
  }

  onStockDetails(symbol) {
    //this.fromWatchlist = this.toolsApi.isFromWatchlist();
    this.context = this.toolsApi.getContext();
    this.toolsApi.getStockDetails(symbol).subscribe((response: any) => {
      if (response && response?.data) {
        this.selectedStockDetails = response?.data?.stockData;
        this.toolsNotesSlug = response?.data?.slug;
        this.watchlistCount = response?.data?.watchlistCount;
        this.alertCount = response?.data?.alertCount;
        this.notesCount = response?.data?.notesCount;
        this.buttonStateGroup.is_watch_list = this.selectedStockDetails.is_watch_list;
        const nameValue = response?.data?.stockData.symbol;
        const dynamicUrl = `https://placehold.co/150@3x/77B84A/white?text=${nameValue}&font=roboto`;
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(dynamicUrl);
        this.loadSymbolGraph();
      }
    });
  }

  openLink(link: string): void {
    window.open(link, '_blank');
  }

  async viewAlert(mode) {
    let watchListProps: any = {
      mode,
      componentType: 'TickerAlertsComponent',
    };
    this.menuService.openRightMenu(watchListProps);
  }

  hideLookupList() {
    this.lookupListVisibility = false;
    this.selectedLookup = {
      name: '',
      symbol: '',
      exch: '',
    };
  }

  onGetLookup() {
    this.toolsApi.getCountryWiseStock('usa').subscribe((res: any) => {
      if (res && res?.data?.length) {
        this.listOfLookup = res.data;
      }
    });
  }

  getAlertList() {
    if (this.toolsNotesSlug == null) {
      const symbol = JSON.parse(localStorage?.toolId)?.slug;
      this.toolsApi.getStockDetails(this.symbol).subscribe((response: any) => {
        if (response && response?.data) {
          this.toolsNotesSlug = response?.data?.slug;

          this.toolsApi.getAlertData(this.toolsNotesSlug).subscribe((res) => {
            if (res.data && res.data?.alertList && res.data?.alertList.length) {
              this.alertData = res.data.alertList;

              // Filter stock price alerts
              this.stockPriceAlerts = this.alertData
                .filter((alert) => alert.type === 'stock_price')
                .sort((a, b) => {
                  // Sort by status: active first, then inactive
                  if (a.status === 'active' && b.status !== 'active') return -1;
                  if (a.status !== 'active' && b.status === 'active') return 1;
                  return 0;
                });

              // Filter date time alerts
              this.dateTimeAlerts = this.alertData
                .filter((alert) => alert.type === 'on_date')
                .sort((a, b) => {
                  // Sort by status: active first, then inactive
                  if (a.status === 'active' && b.status !== 'active') return -1;
                  if (a.status !== 'active' && b.status === 'active') return 1;
                  return 0;
                });
            } else {
              this.alertData = [];
              this.stockPriceAlerts = [];
              this.dateTimeAlerts = [];
            }
          });
        }
      });
    } else {
      this.toolsApi.getAlertData(this.toolsNotesSlug).subscribe((res) => {
        if (res.data && res.data?.alertList && res.data?.alertList.length) {
          this.alertData = res.data.alertList;

          // Filter stock price alerts
          this.stockPriceAlerts = this.alertData
            .filter((alert) => alert.type === 'stock_price')
            .sort((a, b) => {
              // Sort by status: active first, then inactive
              if (a.status === 'active' && b.status !== 'active') return -1;
              if (a.status !== 'active' && b.status === 'active') return 1;
              return 0;
            });

          // Filter date time alerts
          this.dateTimeAlerts = this.alertData
            .filter((alert) => alert.type === 'on_date')
            .sort((a, b) => {
              // Sort by status: active first, then inactive
              if (a.status === 'active' && b.status !== 'active') return -1;
              if (a.status !== 'active' && b.status === 'active') return 1;
              return 0;
            });
        } else {
          this.alertData = [];
          this.stockPriceAlerts = [];
          this.dateTimeAlerts = [];
        }
      });
    }
  }

  getSystemTimezone(): string {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone;
  }

  segmentChangedalert(event: any) {
    this.defaultAlertType = event.detail.value;
  }
  // addLookup(symbol: string, name: string, exchange: string) {
  //   this.router.navigate([
  //     `/${this.role}/tools/dashboard/${this.activeToolSlug}/stock-info/${symbol}`,
  //   ]);
  //   this.onStockDetails(symbol);
  //   this.selectedLookup = { symbol, name, exchange };
  //   this.segment = 'stockInfo';
  // }

  ngAfterViewInit() {
    this.loadSymbolGraph();
  }

  loadSymbolGraph() {
    const hotlistSymbolElement = this?.hotlistsymbol?.nativeElement;
    if (hotlistSymbolElement) hotlistSymbolElement.innerHTML = '';
    let stockSymbol = this.selectedLookup.exch + ':' + this.symbol;
    const script2 = this._renderer2.createElement('script');
    script2.type = `text/javascript`;
    script2.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
    script2.text = `
    {
      "autosize": true,
      "symbol": "${stockSymbol}",
      "interval": "D",
      "timezone": "Etc/UTC",
      "theme": "light",
      "style": "1",
      "locale": "in",
      "enable_publishing": false,
      "withdateranges": true,
      "allow_symbol_change": false,
      "calendar": false,
      "show_popup_button": true,
      "popup_width": "1000",
      "popup_height": "650",
      "support_host": "https://www.tradingview.com"
    }
    `;
    hotlistSymbolElement?.appendChild(script2);
  }

  convertMarketCapToWords(marketCap: number): string {
    if (!marketCap && marketCap !== 0) return '';
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const magnitude = Math.floor(Math.log10(Math.abs(marketCap)) / 3);
    const newValue = (marketCap / Math.pow(10, magnitude * 3)).toFixed(2);
    return `${newValue} ${suffixes[magnitude]}`;
  }

  getHeaderClassTodayChange({ selectedStockDetails }): any {
    return {
      'custom-green': Number(selectedStockDetails?.regularMarketChange) >= 0,
      'custom-red': Number(selectedStockDetails?.regularMarketChange) < 0,
      'custom-black': Number(selectedStockDetails?.regularMarketChange) === 0,
    };
  }

  getHeaderClassTodayChange1({ selectedStockDetails }): any {
    return {
      'green-text': Number(selectedStockDetails?.regularMarketChange) >= 0,
      'red-text': Number(selectedStockDetails?.regularMarketChange) < 0,
      'black-text': Number(selectedStockDetails?.regularMarketChange) === 0,
    };
  }
  getHeaderClassPreChange({ selectedStockDetails }): any {
    return {
      'custom-green': Number(selectedStockDetails?.preMarketChangePercent) >= 0,
      'custom-red': Number(selectedStockDetails?.preMarketChangePercent) < 0,
      'custom-black': Number(selectedStockDetails?.preMarketChangePercent) === 0,
    };
  }
  getHeaderClassPostChange({ selectedStockDetails }): any {
    return {
      'custom-green': Number(selectedStockDetails?.postMarketChangePercent) >= 0,
      'custom-red': Number(selectedStockDetails?.postMarketChangePercent) < 0,
      'custom-black': Number(selectedStockDetails?.postMarketChangePercent) === 0,
    };
  }

  getHeaderClassPostChange1({ selectedStockDetails }): any {
    return {
      'green-text': Number(selectedStockDetails?.postMarketChangePercent) >= 0,
      'red-text': Number(selectedStockDetails?.postMarketChangePercent) < 0,
      'black-text': Number(selectedStockDetails?.postMarketChangePercent) === 0,
    };
  }

  getHeaderClassPreChange1({ selectedStockDetails }): any {
    return {
      'green-text': Number(selectedStockDetails?.preMarketChangePercent) >= 0,
      'red-text': Number(selectedStockDetails?.preMarketChangePercent) < 0,
      'black-text': Number(selectedStockDetails?.preMarketChangePercent) === 0,
    };
  }

  segmentChanged(ev: any) {
    this.segment = ev.detail.value;
    if (this.segment === 'stockInfo') {
      this.cdr.detectChanges();
      this.loadSymbolGraph();
    } else if (this.segment === 'alert') {
      this.getAlertList();
    }
  }
  onRecentNews(symbol, disableHttpLoader = 'true') {
    this.isLoading = true;
    this.toolsApi.getRecentNews(symbol, disableHttpLoader).subscribe(
      (result: any) => {
        const {
          data: { newsList = [] },
          meta,
        } = result;
        this.page = {
          offset: parseInt(meta?.page, 10) - 1,
          size: 10,
          totalElements: newsList.length,
          totalPages: 20,
        };
        this.selectedNews = newsList;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching recent news:', error);

        // Ensure isLoading is set to false even if there's an error
        this.isLoading = false;
      }
    );
  }

  destroyInterval() {
    if (this.interval) {
      this.timeOutPageTime = 0;
      clearInterval(this.interval);
      this.interval = 0;
    }
  }

  getSymbolWiseNotes(symbol, disableHttpLoader = 'true') {
    this.isLoading = true;
    this.toolsApi.getSymbolWiseNotes(symbol, disableHttpLoader).subscribe(
      (result: any) => {
        const { meta } = result;
        this.page = {
          offset: parseInt(meta?.page, 10) - 1,
          size: 10,
          totalElements: result.dataLength,
          totalPages: 20,
        };
        this.selectedNotes = result.data.sort((a, b) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });

        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching recent Notes:', error);
        this.isLoading = false;
      }
    );
  }

  openRightMenuToolNotes(mode: 'edit' | 'create', noteDetail?: any) {
    const toolNotesData = {
      componentType: 'toolNotesComponent',
      symbol: this.selectedStockDetails?.symbol ?? '',
      slug: this.toolsNotesSlug,
      noteDetail: mode === 'edit' ? noteDetail : null,
    };
    this.menuService.openRightMenu(toolNotesData);
  }

  handletoolInit(tool) {
    this.tool = tool;
    if (this.tool && this.role) {
      const { readonly = false, postAllowedRoles = [], status = null, tags } = this.tool;
      if (status && status === 'locked' && !readonly) {
        const allIdsExist = tags.some((id) => this.tag.includes(Number(id)));
        if (allIdsExist) {
          this.isToolAccess = true;
          this.lockedPageContent = null;
        } else {
          this.loaderService.HideLoader();
          this.toolTitle = tool.title;
          this.lockedPageContent = tool.lockedPageContent;
        }
      } else if (status && status === 'published') {
        this.isToolAccess = true;
        this.lockedPageContent = null;
      } else {
        if (readonly) {
          this.toolIsReadOnlyForUser = !postAllowedRoles.includes(this.userRole);
          if (this.toolIsReadOnlyForUser) {
            this.toolReadOnlyMessage =
              'The channel has been opened with specific levels of access, you are not permitted to post. For more information, please email thecrew@tradersedgenetwork.com.';
          }
        }
      }
    }
  }

  deleteNotes(row) {
    this.toastServ
      .confirmationAlert('Are you sure you want to delete this note?', 'Delete')
      .then((confirm) => {
        if (confirm) {
          this.toolsApi.deleteNote(row.slug).subscribe((response: any) => {
            this.toastServ.presentToast(response.message, 'success');
            this.getSymbolWiseNotes(this.selectedStockDetails.symbol);
            this.onStockDetails(this.symbol);
          });
        }
      });
  }

  deleteAlert(alertList) {
    this.toolsApi.deleteAlert(alertList._id).subscribe((response: any) => {
      this.toastServ.presentToast(response.message, 'success');
      this.getAlertList();
    });
  }

  async showAlertDelete(alertList: string) {
    this.isDropdownOpen = !this.isDropdownOpen;
    const alert = await this.alertController.create({
      cssClass: 'custom-alert-popup',
      subHeader: 'Are you sure to delete this alert?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'ghost-button',
        },
        {
          text: 'Delete',
          cssClass: 'delete-button',
          handler: () => {
            this.deleteAlert(alertList);
          },
        },
      ],
    });
    await alert.present();
  }

  getTradeHistoryList() {
    this.tradeHistoryList = [];
    const symbol = {
      symbol: this.symbol,
      // trackerProductId: this.tradeHistoryData?.trackerProductId,
    };
    this.toolsApi.getTradeSymbol(symbol).subscribe((response: any) => {
      this.tradeHistoryList = response.data.tickerList;
      this.symbol = response.data.tickerList[0].symbol;
    });

    // if (this.toolsNotesSlug == null || this.toolsNotesSlug == '') {
    //   this.toolsApi.getStockDetails(this.symbol).subscribe((response: any) => {
    //     if (response && response?.data) {
    //       this.toolsNotesSlug = response?.data?.slug;
    //       if (response?.data?.slug != '') {
    //         const slug = { slug: this.toolsNotesSlug };

    //         this.toolsApi.getTradeHistory(slug).subscribe((response: any) => {
    //           this.tradeHistoryList = response.data.tickerList;
    //         });
    //       } else {
    //         this.tradeHistoryList = [];
    //         this.toastServ.presentToast('No trade exists for the ' + this.symbol + '.', 'success');
    //       }
    //     }
    //   });
    // } else {
    //   const slug = { slug: this.toolsNotesSlug };

    //   this.toolsApi.getTradeHistory(slug).subscribe((response: any) => {
    //     this.tradeHistoryList = response.data.tickerList;
    //   });

    // }
  }
  calculateDaysDifference(startDate: any, endDate: any, status: any) {
    this.daysDifference = 0;
    const start = new Date(startDate);
    const end = status == closed ? (endDate ? new Date(endDate) : new Date()) : new Date();
    const timeDifference = end.getTime() - start.getTime();

    this.daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    return this.daysDifference || 0;
  }
  async onRedirectInsider() {
    const modal = await this.modalController.create({
      component: TradeTrackersInsidersComponent,
      cssClass: 'my-modal-class insider-modal',
      componentProps: { page: 'Tools' },
    });
    modal.onDidDismiss().then(() => {});

    return await modal.present();
  }
}

export interface AlertRow {
  triggerPrice: any;
  triggerDate: Date;
}
