<ion-content class="ion-padding">
  <div class="general-main" *ngIf="!isToolAccess">
    <div class="no-data-container">
      <div class="no-data">
        <img src="/assets/icon/locked.png" alt="" />
        <p *ngIf="!isToolAccess && !lockedPageContent">
          Please be patience, reviewing tool access.
        </p>
        <p
          *ngIf="!isToolAccess && lockedPageContent"
          [innerHTML]="lockedPageContent | keepHtml"
        ></p>
      </div>
    </div>
  </div>
  <div *ngIf="isToolAccess && !lockedPageContent">
    <ol class="cd-breadcrumb custom-separator custom-icons">
      <li><a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools</a></li>
      <li>
        <a routerLink="/{{ role }}/tools/trade-trackers/{{ activeToolSlug }}"
          >Tools Trade Trackers</a
        >
      </li>
      <li class="current" *ngIf="selectedLookup && selectedLookup?.symbol">
        {{ selectedLookup?.symbol | uppercase }}
      </li>
    </ol>
    <div class="page-heading">
      <h2>Trade Trackers</h2>
    </div>
    <div class="card-container">
      <div
        *ngFor="let product of productList?.trackerProducts"
        class="custom-card custom-card-block"
      >
        <div
          class="insiders-class"
          *ngIf="isRegularAccess || (product.productTitle !== 'Money Press Method' && isLiteAccess)"
        >
          <div class="insider-body">
            <div class="insider-lock">
              <img src="../../../../../../assets/icon/lock_svgrepo.com.svg" alt="icon" />
            </div>
            <p>You must be subscribed to insider max to access this feature</p>
            <div class="insider-max">
              <img src="../../../../../../assets/icon/InsiderMaxx-RGB.svg" alt="icon" />
            </div>
          </div>
        </div>
        <div class="card-content" *ngIf="product?.status === 'active'">
          <div class="product-wrapper">
            <div class="product-description-container">
              <img class="product-image" src="{{ product.productImage }}" />
              <!-- <img class="product-image" src="../../../../../../assets/icon/MIT.png" /> -->
              <div class="product-description">
                <div class="product-name">{{ product.productTitle }}</div>
                <span class="product-desc">
                  {{ product.productDescription }}
                </span>
              </div>
            </div>
            <div class="product-positions-container">
              <div class="product-positions-wrapper">
                <div class="open-postions">
                  <span class="count">{{ product.openTrades }}</span>
                  <span class="text">Open Trades</span>
                </div>
                <div class="close-postions">
                  <span class="count">{{ product.closedTrades }}</span>
                  <span class="text">Closed Trades</span>
                </div>
              </div>
              <div class="product-view-positions">
                <ion-button
                  fill="clear"
                  color="primary"
                  routerLink="/{{ role }}/tools/trade-trackers/{{ tool.slug }}/product/{{
                    product.slug
                  }}"
                  >View more</ion-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="inactive-custom" *ngIf="product?.status === 'inactive'">
          <ion-icon name="construct-outline"></ion-icon>
          <h1>Under construction</h1>
        </div>
      </div>
    </div>
  </div>
</ion-content>
