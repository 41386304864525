import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-stock-detail-watchlist-create-modal',
  template: `
    <div>
      <ion-header class="ion-no-border">
        <ion-toolbar>
          <!-- <ion-buttons slot="end">
            <ion-button (click)="close()">
              <ion-icon slot="icon-only" name="close-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-title>Add To Watchlist</ion-title> -->
          <div class="drawer-header">
            <div class="header-container">
              <div class="page-header">
                <div class="page-heading-text">Add to Watchlist</div>
                <ion-buttons slot="end">
                  <ion-button (click)="close()">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 7L7 21M7 7L21 21"
                        stroke="#667085"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </ion-button>
                </ion-buttons>
              </div>
              <!-- <div>
                <span class="span-text">You can select multiple watchlist</span>
              </div> -->
            </div>
          </div>
        </ion-toolbar>
      </ion-header>
      <ngx-datatable
        #externalSpendTable
        class="material body-cell-stacked"
        [rows]="watchList"
        [columnMode]="'force'"
        [columnMode]="'force'"
        [rowHeight]="'100%'"
        [headerHeight]="0"
        [footerHeight]="0"
        [scrollbarH]="true"
      >
        <ngx-datatable-column [width]="120" [sortable]="false" [resizeable]="false">
          <!-- <ng-template ngx-datatable-header-template>COMPANY NAME</ng-template> -->
          <ng-template
            let-row="row"
            let-name="row?.name"
            let-id="row?._id"
            let-symbolCount="row?.symbolCount"
            ngx-datatable-cell-template
          >
            <div style="display: flex;flex-direction: column;">
              <span class="row-company-data">{{ name }} ({{ symbolCount }})</span>
              <span
                *ngIf="row.symbols.includes(selectedSymbol)"
                (click)="onWatchListSelect(row)"
                class="watchlist-text"
                >Added to watchlist</span
              >
            </div>
          </ng-template>
        </ngx-datatable-column>
        <!-- <ngx-datatable-column [sortable]="false">
          <ng-template
            let-row="row"
            let-name="row?.name"
            let-id="row?._id"
            let-symbolCount="row?.symbolCount"
            ngx-datatable-cell-template
          >
            <div class="watchlist-data">
              <span> {{ name }} ({{ symbolCount }}) </span>
              <ion-checkbox
                class="checkmark"
                [(ngModel)]="row.selected"
                (ionChange)="onWatchListSelect(row)"
              ></ion-checkbox>
            </div>
          </ng-template>
        </ngx-datatable-column> -->
        <ngx-datatable-column [sortable]="false" [resizeable]="false">
          <!-- <ng-template ngx-datatable-header-template> ACTION </ng-template> -->
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="crypto-details-button">
              <ion-button
                class="color-css1"
                fill="outline"
                *ngIf="!row.symbols.includes(selectedSymbol)"
                (click)="onWatchListSelect(row)"
              >
                <ion-icon name="add-outline" fill="outline"></ion-icon>
                Add to Watchlist
              </ion-button>
              <ion-button
                class="color-css1 remove-btn"
                fill="outline"
                *ngIf="row.symbols.includes(selectedSymbol)"
                (click)="onWatchListSelect(row)"
              >
                Remove
              </ion-button>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
      <ion-buttons *ngIf="!showInput">
        <ion-button color="primary" (click)="createWatchlist()">
          <ion-icon slot="start" name="add-outline"></ion-icon>
          Create Watchlist
        </ion-button>
      </ion-buttons>
      <ion-content class="ion-padding-horizontal create-watchlist" *ngIf="showInput">
        <form [formGroup]="watchListForm">
          <ion-row>
            <ion-col size-xl="12" size-lg="12">
              <ion-item lines="inset" class="ion-item-no-inner-padding">
                <ion-label position="floating">Create Watchlist</ion-label>
                <ion-input style="width: 80%;" inputmode="text" formControlName="name"></ion-input>
                <ion-button
                  *ngIf="showInput"
                  color="primary"
                  class="create-btn"
                  style="width: 100%"
                  (click)="saveWatchlist()"
                >
                  create
                </ion-button>
              </ion-item>
            </ion-col>
            <!-- <ion-col size-xl="6" size-lg="6">
              <ion-item lines="inset" class="ion-item-no-inner-padding">
                <ion-label position="floating">Watchlist Description</ion-label>
                <ion-input inputmode="text" formControlName="description"></ion-input>
              </ion-item>
            </ion-col> -->
          </ion-row>
        </form>
      </ion-content>

      <div *ngIf="isShow" class="add-watch-list">
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="25" height="25" rx="12.5" fill="#F2F2F2" />
          <mask
            id="mask0_8076_4142"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="3"
            y="3"
            width="19"
            height="19"
          >
            <rect x="3.125" y="3.125" width="18.75" height="18.75" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_8076_4142)">
            <path
              d="M11.4063 16.0938L16.9141 10.5859L15.8203 9.49219L11.4063 13.9062L9.17969 11.6797L8.08594 12.7734L11.4063 16.0938ZM12.5 20.3125C11.4193 20.3125 10.4036 20.1073 9.45313 19.6969C8.5026 19.287 7.67578 18.7305 6.97266 18.0273C6.26953 17.3242 5.71302 16.4974 5.30313 15.5469C4.89271 14.5964 4.6875 13.5807 4.6875 12.5C4.6875 11.4193 4.89271 10.4036 5.30313 9.45313C5.71302 8.5026 6.26953 7.67578 6.97266 6.97266C7.67578 6.26953 8.5026 5.71276 9.45313 5.30234C10.4036 4.89245 11.4193 4.6875 12.5 4.6875C13.5807 4.6875 14.5964 4.89245 15.5469 5.30234C16.4974 5.71276 17.3242 6.26953 18.0273 6.97266C18.7305 7.67578 19.287 8.5026 19.6969 9.45313C20.1073 10.4036 20.3125 11.4193 20.3125 12.5C20.3125 13.5807 20.1073 14.5964 19.6969 15.5469C19.287 16.4974 18.7305 17.3242 18.0273 18.0273C17.3242 18.7305 16.4974 19.287 15.5469 19.6969C14.5964 20.1073 13.5807 20.3125 12.5 20.3125Z"
              fill="#8ABF47"
            />
          </g>
        </svg>
        <div class="message">{{ message }}</div>
        <div class="closebutton">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            (click)="closeNotification()"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L9 9M1 9L9 1"
              stroke="#4B4B4B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <ion-progress-bar [value]="progress"></ion-progress-bar>
      </div>
      <!-- <ion-toolbar class="footer-toolbar">
        <ion-buttons slot="end">
          <ion-button color="dark" fill="outline" (click)="close()"> Cancel </ion-button>
          <ion-button color="primary" fill="solid" (click)="onSubmit()"> ADD </ion-button>
        </ion-buttons>
      </ion-toolbar> -->
    </div>
  `,
  styleUrls: ['./tool-dashboard.component.scss'],
})
export class StockDetailWatchListCreateModalComponent implements OnInit {
  public watchListForm: FormGroup;
  @Input() selectedSymbol;
  showInput: boolean;
  selectedWatchListIds: string[] = [];
  selectedSymbols: string[] = [];
  watchList: any;
  watchListCount: number = 0;
  selectedId: any;
  selectedAllWatchListIds: any = [];
  public progress = 1;
  message: any = '';
  isShow: boolean = false;
  constructor(
    public modalController: ModalController,
    private fb: FormBuilder,
    private toolsApi: ToolServiceService,
    private toastServ: ToastService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isShow = false;
    this.watchListForm = this.fb.group({
      watchListId: '',
      name: '',
      symbol: this.selectedSymbol,
      description: '',
    });
    this.onListForWatchList();
  }
  reverseProgress() {
    let progress = this.progress;

    // Decrease the progress from 100% to 0%
    const interval = setInterval(() => {
      progress -= 0.01; // Decrease by small steps

      if (progress <= 0) {
        clearInterval(interval); // Stop once progress reaches 0
        progress = 0;
      }

      this.progress = progress;
      // Manually trigger change detection
      this.cdr.detectChanges();
    }, 50); // Adjust the speed by changing the interval time
  }
  onListForWatchList() {
    this.toolsApi.getForWatchList().subscribe((response: any) => {
      if (response && response?.data) {
        this.watchList = response?.data.map((item) => ({
          ...item,
          selected: item.symbols.includes(this.selectedSymbol),
        }));
      }
    });
  }

  close() {
    this.modalController.dismiss(null);
  }

  createWatchlist() {
    this.showInput = !this.showInput;
  }

  onWatchListSelect(selectedWatchList) {
    const index = this.selectedWatchListIds.indexOf(selectedWatchList._id);
    const symbolIndex = this.selectedSymbols.indexOf(this.selectedSymbol);
    let isSelected = selectedWatchList?.symbols?.includes(this.selectedSymbol);
    this.selectedId = selectedWatchList;
    this.onSubmit();
    if (!isSelected) {
      // if (index === -1) {
      //   this.selectedWatchListIds.push(selectedWatchList._id);
      //   this.selectedSymbols.push(this.selectedSymbol);
      // }
    } else {
      // if (index > -1) {
      //   this.selectedWatchListIds.splice(index, 1);
      // }
      // if (symbolIndex > -1) {
      //   this.selectedSymbols.splice(symbolIndex, 1);
      // }
      // this.toolsApi.removeFromWatchList(this.selectedSymbol).subscribe((response: any) => {
      //   if (response && response.message) {
      //     this.onListForWatchList();
      //     // this.isShow = true;
      //     // this.message = row.symbol + ' Ticker removed to your watchlist';
      //     // this.progress = 1;
      //     // this.reverseProgress();
      //     // setTimeout(() => {
      //     //   if (this.progress === 0) {
      //     //     this.closeNotification();
      //     //   }
      //     // }, 5000);
      //   }
      // });
    }
    this.watchListCount = this.selectedWatchListIds.length;
  }

  onSubmit() {
    if (this.watchListForm.valid) {
      const { value: form } = this.watchListForm;
      let isSelected = false;
      let index = this.watchList.indexOf(this.selectedId);
      if (index != -1) {
        isSelected = this.watchList[index]?.symbols?.includes(this.selectedSymbol);
        if (isSelected) {
          this.watchList[index]['selected'] = false;
        }
      }
      this.selectedAllWatchListIds = this.watchList
        .filter((item) => item.selected)
        .map((item) => item._id);
      if (!!this.selectedId && !isSelected) {
        this.selectedAllWatchListIds.push(this.selectedId._id);
      }
      let addUpdateclone: any;
      addUpdateclone = {
        name: form.name,
        symbol: !!form.symbol ? form.symbol : this.selectedSymbol,
        description: form.description,
        watchListIds: this.selectedAllWatchListIds,
        watchListCount: 1,
      };
      if (this.selectedAllWatchListIds.length == 0) {
        this.toolsApi.removeFromWatchList(addUpdateclone?.symbol).subscribe((response: any) => {
          if (response && response.message) {
            this.onListForWatchList();
            this.watchListForm.reset();
            this.showInput = false;

            this.isShow = true;
            this.message = this.selectedSymbol + ' was removed from your watchlist.';
            this.progress = 1;
            this.reverseProgress();
            setTimeout(() => this.closeNotification(), 5000);
          }
        });
      } else {
        this.toolsApi.addWatchList(addUpdateclone).subscribe((response: any) => {
          if (response && response.message) {
            this.onListForWatchList();
            this.watchListForm.reset();
            this.showInput = false;
            if (form.name) {
              this.toastServ.presentToast(response.message, 'success');
            } else {
              this.isShow = true;
              this.message = isSelected
                ? this.selectedSymbol + ' was removed from your watchlist.'
                : this.selectedSymbol + ' was added to your watchlist';
              this.progress = 1;
              this.reverseProgress();
              setTimeout(() => this.closeNotification(), 5000);
            }
          }
        });
      }
      // this.modalController.dismiss(addUpdateclone);
    }
  }
  saveWatchlist() {
    if (this.watchListForm.valid) {
      const { value: form } = this.watchListForm;
      const addUpdateclone = {
        name: form.name,
        description: '',
      };
      this.toolsApi.addWatchList(addUpdateclone).subscribe((response: any) => {
        if (response && response.message) {
          this.onListForWatchList();
          this.watchListForm.reset();
          this.showInput = false;
          this.toastServ.presentToast(response.message, 'success');
        }
      });
    }
  }
  closeNotification() {
    this.isShow = false;
  }
}
