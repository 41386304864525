import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WatchListComponent } from './watch-list.component';
import { WatchListDataListingComponent } from './watch-list-data-listing/watch-list-data-listing.component';
import { AuthGuard } from 'src/app/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: WatchListComponent,
  },
  /* {
    path: 'watchList/:id',
    component: WatchListComponent,
  }, */
  {
    path: 'product/:productName',
    component: WatchListDataListingComponent,
    canActivate: [AuthGuard],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WatchListRoutingModule {}
