<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content
  ><div class="custom-card-block">
    <div class="inactive-custom">
      <ion-icon name="construct-outline"></ion-icon>
      <h1>Under construction</h1>
    </div>
  </div>
</ion-content>
