<div>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title *ngIf="recentData?.alertType == 'stock_price'">Stock Price Alert</ion-title>
      <ion-title *ngIf="recentData?.alertType == 'on_date'">Date/Time Alert</ion-title>
    </ion-toolbar>
  </ion-header>
  <div class="body-modal">
    <div class="alerts-title">
      <div class="alert-svg" *ngIf="recentData?.alertType == 'stock_price'">
        <img src="/assets/icon/Group 217 (1).png" />
      </div>
      <div class="alert-svg" *ngIf="recentData?.alertType == 'on_date'">
        <img src="/assets/icon/Group 217.png" />
      </div>
      <div class="alert-text">
        <div class="symbol-img-name">
          <!-- <img
            aria-placeholder="stockimage"
            class="size-css"
            src="{{ recentData?.logoUrl }}"
          /> -->
          <span>{{ recentData?.symbol }}</span>
        </div>
        <div class="title-name" *ngIf="recentData?.alertType == 'stock_price'">
          Price crossed <span class="green-text">${{ recentData?.alertPrice }}.</span>
        </div>
        <div *ngIf="recentData?.alertType == 'on_date'" class="title-name">Alert Triggered</div>
        <span class="date"
          >on {{ recentData?.triggerDate | date : 'MMM d, h:mm a' }} ({{
            getSystemTimezone()
          }})</span
        >
        <!-- <span class="date" *ngIf="recentData?.alertType == 'stock_price'" -->
        <!-- >on {{ recentData?.triggerDate | date : 'MMM d, h:mm a' }}</span -->
        <!-- > -->
      </div>
    </div>
    <div class="alerts-mesg">
      <span>Message:</span>
      <p>{{ recentData?.message }}</p>
    </div>
  </div>
  <div class="footer-modal">
    <ion-button class="close-btn" fill="outline" (click)="close()">Close</ion-button>
    <ion-button
      *ngIf="!isHistory"
      type="submit"
      color="primary"
      fill="solid"
      (click)="onSymbolWiseView(recentData?.symbol, 'alert', recentData?.alertType)"
      >VIEW STOCK</ion-button
    >
  </div>
</div>
