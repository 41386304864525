import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { NotificationService } from 'src/app/services/notification.service';
import { StorageService } from 'src/app/services/storage.service';
import { Capacitor } from '@capacitor/core';
import { Page } from '../../models/page';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-common-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  notifications: any = [];
  formatedNotifications: any = [];
  role: string = localStorage.getItem('role');
  public page = new Page();
  @Output() onNotificationCount = new EventEmitter<any>();

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  totalCount = 0;
  unreadCount = 0;
  platfrom = Capacitor.getPlatform();
  constructor(
    private notificationService: NotificationService,
    private storageserv: StorageService,
    private router: Router,
    public alertController: AlertController,
    private toolsApi: ToolServiceService
  ) {}

  ngOnInit() {
    this.page.offset = 1;
    this.page.size = 20;
    this.getNotifications();
    this.notificationService.unreadCount$.subscribe((updatedUnreadCount) => {
      if (updatedUnreadCount == -1) {
        this.getNotifications();
      }
    });
  }

  getNotifications() {
    this.notificationService
      .fetchAllNotifications(this.page.offset, this.page.size)
      .subscribe((response: any) => {
        if (response) {
          const { notifications, total, unread_total } = response;
          this.totalCount = total;
          this.unreadCount = unread_total;
          this.onNotificationCount.emit({ total, unread_total });
          this.notificationService.updateUnreadCount(this.unreadCount);
          this.page.totalPages = total / this.page.size;
          if (this.page.offset === 1) {
            this.notifications = notifications;
            this.formatedNotifications = this.formatNotifications(this.notifications);
          } else {
            this.notifications = [...this.notifications, ...notifications];
            this.formatedNotifications = this.formatNotifications(this.notifications);
          }
        }
      });
  }

  formatNotifications(notifications) {
    if (!notifications.length) {
      return [];
    }
    const notificationArray = [];
    const getAuthorName = (author) => {
      return `${author.firstName || ''} ${author.lastName || ''}`;
    };
    const getNotificationHref = (
      loggedInUserRole,
      notificationType,
      groupSlug,
      productSlug,
      categorySlug,
      subCategorySlug = null,
      postSlug,
      commentID,
      depth
    ) => {
      if (notificationType === 'ChatComment') {
        if (loggedInUserRole !== 'user') {
          if (subCategorySlug) {
            return {
              url: `/${loggedInUserRole}/products/comments/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${subCategorySlug}/sub-content/${postSlug}`,
              queryParams: { focus: commentID, depth },
            };
          } else {
            return {
              url: `/${loggedInUserRole}/products/comments/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${postSlug}`,
              queryParams: { focus: commentID, depth },
            };
          }
        } else {
          if (subCategorySlug) {
            return {
              url: `/${loggedInUserRole}/product-groups/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${subCategorySlug}/sub-content/${postSlug}`,
              queryParams: { focus: commentID, depth },
            };
          } else {
            return {
              url: `/${loggedInUserRole}/product-groups/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${postSlug}`,
              queryParams: { focus: commentID, depth },
            };
          }
        }
      } else if (notificationType === 'Broadcast') {
        if (['admin', 'staff'].includes(loggedInUserRole)) {
          if (subCategorySlug) {
            return {
              url: `/${loggedInUserRole}/products/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${subCategorySlug}/sub-content/${postSlug}`,
              queryParams: {},
            };
          } else {
            return {
              url: `/${loggedInUserRole}/products/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${postSlug}`,
              queryParams: {},
            };
          }
        } else if (loggedInUserRole === 'user') {
          if (subCategorySlug) {
            return {
              url: `/${loggedInUserRole}/product-groups/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${subCategorySlug}/sub-content/${postSlug}`,
              queryParams: {},
            };
          } else {
            return {
              url: `/${loggedInUserRole}/product-groups/${groupSlug}/product/${productSlug}
              /category/${categorySlug}/content/${postSlug}`,
              queryParams: {},
            };
          }
        }
      }
    };
    /* tslint:disable */
    for (let i = 0; i < notifications.length; i++) {
      const notification = notifications[i];
      const loggedInUserId = this.storageserv.get('userId');

      if (!notification.notificationType) {
        continue;
      }

      const {
        comment = null,
        subCategory = null,
        post = null,
        postId,
        createdAt,
        is_read = false,
        thread,
        channel,
        parentThread,
      } = notification;
      let postDetails = null;
      let postProductDetails = null;
      let groupSlug = null;
      let productSlug = null;
      let categorySlug = null;
      let subCategorySlug = null;
      let postSlug = null;
      let commentID = null;
      let depth = 0;

      switch (notification.notificationType) {
        case 'ChatComment':
          if (!notification.comment) {
            continue;
          }
          if (!post && subCategory && subCategory.category && subCategory.posts) {
            const { posts = [], category } = subCategory;
            const subCategoryPost = posts.find((p) => p._id === postId);
            if (!subCategoryPost || !category || !category.product || !category.product.group) {
              continue;
            }

            postDetails = subCategoryPost;
            postProductDetails = category.product;
            groupSlug = category.product.group.slug;
            productSlug = category.product.slug;
            categorySlug = category.slug;
            subCategorySlug = subCategory.slug;
            postSlug = subCategoryPost.slug;
            commentID = comment._id;
            depth = comment.depth;
          } else if (post && !subCategory) {
            const { category } = post;
            if (!category || !category.product || !category.product.group) {
              continue;
            }

            postDetails = post;
            postProductDetails = category.product;
            groupSlug = category.product.group.slug;
            productSlug = category.product.slug;
            categorySlug = category.slug;
            postSlug = post.slug;
            commentID = comment._id;
            depth = comment.depth;
          }

          if (postDetails && postProductDetails) {
            const { authorId: commentAuthor = null } = comment;
            const authorName = getAuthorName(commentAuthor);
            const commentAuthorDetails = !!commentAuthor ? { ...commentAuthor } : {};

            if (commentAuthorDetails.image) {
              commentAuthorDetails.image = commentAuthorDetails.image;
            } else {
              commentAuthorDetails.image = '';
            }

            const { title: postTitle = '' } = postDetails;
            const { title: productTitle = '' } = postProductDetails;
            const notificationURL = getNotificationHref(
              this.role,
              notification.notificationType,
              groupSlug,
              productSlug,
              categorySlug,
              subCategorySlug,
              postSlug,
              commentID,
              depth
            );
            if (
              loggedInUserId &&
              notification.parentComment &&
              notification.parentComment.authorId &&
              notification.parentComment.authorId._id === loggedInUserId
            ) {
              const ntfs = {
                id: notification.userNotificationId,
                message: `<div class="message"><span>${authorName}</span> has replied to your comment in post
                <span>${postTitle}</span> in product <span>${productTitle}<span></div>`,
                commentAuthor: commentAuthorDetails,
                comment,
                dateTime: createdAt,
                isRead: is_read,
                hrefLink: notificationURL,
                type: notification.notificationType,
              };

              notificationArray.push(ntfs);
            } else {
              const ntfs = {
                id: notification.userNotificationId,
                message: `<div class="message"><span>${authorName}</span> commented on the post
                <span>${postTitle}</span> in product <span>${productTitle}<span></div>`,
                commentAuthor: commentAuthorDetails,
                comment,
                dateTime: createdAt,
                isRead: is_read,
                hrefLink: notificationURL,
                type: notification.notificationType,
              };

              notificationArray.push(ntfs);
            }
          }
          break;
        case 'Broadcast':
          if (!post && subCategory && subCategory.category && subCategory.posts) {
            const { posts = [], category } = subCategory;
            const subCategoryPost = posts.find((p) => p._id === postId);
            if (!subCategoryPost || !category || !category.product || !category.product.group) {
              continue;
            }

            postDetails = subCategoryPost;
            postProductDetails = category.product;
            groupSlug = category.product.group.slug;
            productSlug = category.product.slug;
            categorySlug = category.slug;
            subCategorySlug = subCategory.slug;
            postSlug = subCategoryPost.slug;
          } else if (post && !subCategory) {
            const { category } = post;
            if (!category || !category.product || !category.product.group) {
              continue;
            }

            postDetails = post;
            postProductDetails = category.product;
            groupSlug = category.product.group.slug;
            productSlug = category.product.slug;
            categorySlug = category.slug;
            postSlug = post.slug;
          }

          if (postDetails && postProductDetails) {
            const { title: postTitle = '', body: postBody = '' } = postDetails;
            const notificationURL = getNotificationHref(
              this.role,
              notification.notificationType,
              groupSlug,
              productSlug,
              categorySlug,
              subCategorySlug,
              postSlug,
              commentID,
              depth
            );
            const ntfs = {
              id: notification.userNotificationId,
              subject: postTitle,
              message: postBody,
              dateTime: createdAt,
              isRead: is_read,
              hrefLink: notificationURL,
              type: notification.notificationType,
            };

            notificationArray.push(ntfs);
          }
          break;
        case 'Alert':
          let activeToolSlug: any = 'r6VAKez';
          // activeToolSlug = JSON.parse(localStorage?.toolId)?.slug;
          if (notification.alertType == 'stock_price') {
            const ntfs = {
              id: notification.userNotificationId,
              postBody: `<div class="message">
                <span>${notification.symbol}</span> Price crossed <span>$ ${notification.alertPrice}<span></div>`,
              message: `<div class="message">
                <span>${notification.message}</span>`,
              dateTime: notification.createdAt,
              isRead: is_read,
              hrefLink: {
                url: `/${this.role}/tools/dashboard/${activeToolSlug}/stock-info/${notification.symbol}`,
              },
              alertType: notification.alertType,
              type: notification.notificationType,
            };
            notificationArray.push(ntfs);
          } else if (notification.alertType == 'on_date') {
            const ntfs = {
              id: notification.userNotificationId,
              postBody: `<div class="message">
             <span>Date/Time </span>Alert triggered</div>`,
              message: `<div class="message">
              <span>${notification.message}</span>`,
              dateTime: notification.createdAt,
              isRead: is_read,
              hrefLink: {
                url: `/${this.role}/tools/dashboard/${activeToolSlug}/stock-info/${notification.symbol}`,
              },
              alertType: notification.alertType,
              type: notification.notificationType,
            };

            notificationArray.push(ntfs);
          } else if (notification.alertType == 'smart_alert') {
            let tradeSlug: any = '9ibJ9zY';
            const ntfs = {
              id: notification.userNotificationId,
              postBody: `<div class="message">
             Trade tracker alert triggered</div>`,
              message: `<div class="message">
              <span>${notification.message}</span>`,
              dateTime: notification.createdAt,
              isRead: is_read,
              hrefLink: {
                url: `/${this.role}/tools/trade-trackers/${tradeSlug}/product/${notification?.productSlug}/ticker/${notification?.slug}`,
              },
              alertType: notification.alertType,
              type: notification.notificationType,
            };

            notificationArray.push(ntfs);
          }

          break;

        case 'InsiderChat':
          if (!thread || !channel) {
            continue;
          }

          const { authorId: threadAuthor = null } = thread;
          const authorName = getAuthorName(threadAuthor);
          const channelSettingSlug = channel?.settingId ? channel?.settingId?.slug : 'chat';
          const notificationURL = {
            url: `/${this.role}/platinum-chat/${channelSettingSlug}/channel/${channel.slug}`,
            queryParams: { focus: thread._id },
          };

          if (!threadAuthor.image) {
            threadAuthor.image = '';
          }

          if (
            loggedInUserId &&
            parentThread &&
            parentThread.authorId &&
            parentThread.authorId._id === loggedInUserId
          ) {
            const ntfs = {
              id: notification.userNotificationId,
              message: `<div class="message"><span>${authorName}</span> has replied to your message in
                    <span>${channel.title}</span> insider room.</div>`,
              threadAuthor,
              thread,
              dateTime: createdAt,
              isRead: is_read,
              hrefLink: notificationURL,
              type: notification.notificationType,
            };

            notificationArray.push(ntfs);
          } else {
            const ntfs = {
              id: notification.userNotificationId,
              message: `<div class="message"><span>${authorName}</span> posted a new message in
                    <span>${channel.title}</span> insider room.</div>`,
              threadAuthor,
              thread,
              dateTime: createdAt,
              isRead: is_read,
              hrefLink: notificationURL,
              type: notification.notificationType,
            };

            notificationArray.push(ntfs);
          }
        default:
          break;
      }
    }

    return notificationArray;
  }

  notificationRedirect({ url, queryParams }, notificationId, notificationIndex, alertType) {
    this.markAsRead(notificationId, notificationIndex);
    this.toolsApi.setSegmentValue('alert');
    this.toolsApi.setAlertValue(alertType);
    this.router.navigate([`${url}`], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  markAsRead(notificationId, notificationIndex) {
    this.notificationService.markAsReadNotification(notificationId);
    this.formatedNotifications[notificationIndex].isRead = true;
    this.unreadCount = this.unreadCount - 1;
    if (this.unreadCount > -1) {
      this.onNotificationCount.emit({
        total: this.totalCount,
        unread_total: this.unreadCount,
      });
    }
  }

  markAllRead() {
    this.notificationService.markAllReadNotifications();
    setTimeout(() => {
      this.page.offset = 1;
      this.getNotifications();
    }, 100);
  }

  clearNotification(notification, notificationIndex) {
    this.openConfirmationDialog(
      `<strong>Are you sure you want to delete this notification?</strong>`,
      () => {
        this.notificationService.clearNotification(notification.id);
        this.formatedNotifications.splice(notificationIndex, 1);

        if (!notification.isRead) {
          this.unreadCount = this.unreadCount - 1;
        }

        this.totalCount = this.totalCount - 1;
        this.onNotificationCount.emit({
          total: this.totalCount,
          unread_total: this.unreadCount,
        });
      }
    );
  }

  clearAllNotification() {
    this.openConfirmationDialog(
      `<strong>Are you sure you want to delete all notifications?</strong>`,
      () => {
        setTimeout(() => {
          this.notificationService.clearAllNotifications();
          this.page.offset = 1;
          this.getNotifications();
        }, 100);
      }
    );
  }

  async openConfirmationDialog(message, callback) {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert-popup',
      message,
      buttons: [
        {
          text: 'Delete',
          cssClass: 'delete-button',
          handler: callback,
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'ghost-button',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  async loadNotificationsInfinitely() {
    if (this.page.offset < this.page.totalPages) {
      this.page.offset++;
      this.getNotifications();
    }
  }
}
