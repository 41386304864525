<div>
  <div class="body-modal">
    <div class="modal-data">
      <div class="close-btn">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button (click)="close()">
              <ion-icon slot="icon-only" name="close-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </div>
      <img
        src="../../../../../../assets/icon/InsiderMaxx-RGB.svg"
        alt="insider-max"
        class="max-logo"
      />
      <img
        *ngIf="page == 'Tools'"
        src="../../../../../../assets/icon/lock_svgrepo.com.svg"
        alt="insider-lock"
        class="lock"
      />

      <h4 *ngIf="page !== 'Tools'">Unlock insider Maxx to track more trades</h4>
      <h5 *ngIf="page == 'Tools'">You must be subscribed to Insider Maxx to access this feature</h5>
      <p *ngIf="page !== 'Tools'">
        You can track up to 3 Money Press trades for free. Upgrade to Insider Maxx for unlimited
        trade tracking!
      </p>

      <ion-button
        *ngIf="page == 'Tools'"
        id=""
        fill="solid"
        lines="none"
        class="button-learn-more green-btn ion-no-padding"
        (click)="onClickLearnMore()"
        >LEARN MORE</ion-button
      >
      <ion-button
        *ngIf="page !== 'Tools'"
        id=""
        fill="solid"
        lines="none"
        class="button-learn-more ion-no-padding"
        (click)="onClickLearnMore()"
        >LEARN MORE</ion-button
      >
    </div>
  </div>
</div>
