<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-toggle>
        <ion-button color="medium">
          <ion-icon slot="icon-only" name="menu-outline"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
    <div class="ion-flex-container ion-justify-content-between ion-align-items-center">
      <!-- <img class="app-logo logo-desktop-hide" src="assets/icon/team-hub-logo.png" alt="" /> -->
      <img
        class="app-logo logo-desktop-hide"
        src="assets/icon/InsiderMaxx-RGB.svg"
        alt="Insidermax"
        [ngStyle]="{
          visibility: role == 'user' && isTools ? 'visible' : 'hidden'
        }"
      />
      <div class="profile-section">
        <ion-buttons slot="end">
          <ion-button
            *ngIf="impersonateModeEnabled === 'true' && impersonatePreviewDismissed === 'false'"
            color="medium"
            (click)="helpAddUpdate()"
          >
            <ion-icon slot="icon-only" name="help"></ion-icon>
          </ion-button>
          <ion-button
            color="medium"
            (click)="onDismissPreview()"
            *ngIf="impersonateModeEnabled === 'true' && impersonatePreviewDismissed === 'false'"
          >
            <ion-icon slot="icon-only" color="primary" name="log-out-outline"></ion-icon>
          </ion-button>
          <ion-button
            color="medium"
            (click)="navigateUrl('/search')"
            *ngIf="['admin', 'staff', 'user'].includes(role)"
            class="search-button"
          >
            <ion-icon slot="icon-only" name="search-outline"></ion-icon>
          </ion-button>
          <ion-button (click)="openNotification($event)" color="medium" class="notification-button">
            <ion-icon slot="icon-only" name="notifications-outline"></ion-icon>
            <ion-badge
              *ngIf="notificationBedgeCount > 0"
              class="notifications-badge"
              color="danger"
            >
              {{ notificationBedgeCount }}
            </ion-badge>
          </ion-button>
        </ion-buttons>
        <ion-item
          class="profile-button-item ion-item-no-inner-padding"
          lines="none"
          (click)="openProfileMenu($event)"
        >
          <ion-avatar slot="start">
            <img ngxGravatar [email]="email" src="{{ image }}" />
          </ion-avatar>
          <ion-label color="medium">{{ username }}</ion-label>
        </ion-item>
      </div>
    </div>
  </ion-toolbar>
</ion-header>
