import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-trade-trackers-insiders',
  templateUrl: './trade-trackers-insiders.component.html',
  styleUrls: ['./trade-trackers-insiders.component.scss'],
})
export class TradeTrackersInsidersComponent implements OnInit {
  @Input() page: any;
  constructor(public modalController: ModalController) {}

  ngOnInit() {}
  close() {
    this.modalController.dismiss(null);
  }
  onClickLearnMore() {
    const url = 'https://www.tradersedgenetwork.com/insidermaxx';
    window.open(url, '_blank');
  }
}
