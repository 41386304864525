import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-trade-trackers-alert',
  templateUrl: './trade-trackers-alert.component.html',
  styleUrls: ['./trade-trackers-alert.component.scss'],
})
export class TradeTrackersAlertComponent implements OnInit {
  @Input() alertsData: any;
  alertForm: FormGroup;
  settingForm: FormGroup;
  flag: boolean = true;
  allData: any;
  role: any;
  isSetting: boolean = false;
  constructor(
    public menuController: MenuController,
    private formBuilder: FormBuilder,
    private toolsApi: ToolServiceService,
    private toastServ: ToastService,
    private menuService: commonRightMenuService,
    private router: Router,
    public storageServ: StorageService
  ) {
    this.role = this.storageServ.get('role');
    this.alertForm = this.formBuilder.group({
      earningDate: [true],
      weeklySoldPutProfit: [true],
      longLegExpiration: [true],
      price: [0, Validators.compose([Validators.max(this.alertsData?.stockPrice)])],
      aboveHIH: [false],
      belowHIH: [false],
    });
    this.settingForm = this.formBuilder.group({
      push: [true],
      text: [true],
      mail: [false],
    });
  }
  get errorControl() {
    return this.alertForm.controls;
  }
  ngOnInit() {
    if (this.alertsData && this.alertsData.componentType === 'tradeAlertComponent') {
      this.alertForm.controls.price.setValidators(
        Validators.compose([Validators.required, Validators.max(this.alertsData?.stockPrice)])
      );
      this.alertForm.controls.price.updateValueAndValidity();
      this.getSmartAlert();
    }
    this.menuService.getOpenMenuSubject().subscribe((res: any) => {
      this.alertsData = {};
      if (res && res.componentType === 'tradeAlertComponent') {
        this.flag = true;
        this.alertsData = res;
        this.alertForm.controls.price.setValidators(
          Validators.compose([Validators.required, Validators.max(this.alertsData?.stockPrice)])
        );
        this.alertForm.controls.price.updateValueAndValidity();
        this.getSmartAlert();
      }
    });
  }
  cancel() {
    this.flag = true;
    this.menuController.close();
  }
  getSmartAlert() {
    this.toolsApi.getSmartAlertList(this.alertsData?.slug).subscribe((res: any) => {
      this.allData = res?.data;
      this.alertForm.patchValue({
        earningDate: res?.data?.smartAlert?.earningDate,
        longLegExpiration: res?.data?.smartAlert?.longLegExpiration,
        weeklySoldPutProfit: res?.data?.smartAlert?.weeklySoldPutProfit,
        aboveHIH: res?.data?.smartAlert?.hihLine?.aboveHIH,
        belowHIH: res?.data?.smartAlert?.hihLine?.belowHIH,
        price: res?.data?.smartAlert?.hihLine?.price,
      });
      if (res?.data?.smartAlert?.hihLine?.price > 0) {
        this.flag = false;
      }
      if (res?.data?.smartAlertSetting) {
        this.settingForm.patchValue({
          push: res?.data?.smartAlertSetting?.push,
          text: res?.data?.smartAlertSetting?.text,
          mail: res?.data?.smartAlertSetting?.mail,
        });
      }
    });
  }
  saveHIH() {
    if (
      this.alertForm.controls.price.value <= this.alertsData.stockPrice &&
      this.alertForm?.controls.price.value > 0
    ) {
      const formData = {
        hihLine: {
          price: this.alertForm?.controls.price.value,
          aboveHIH: this.alertForm?.controls?.aboveHIH.value,
          belowHIH: this.alertForm?.controls?.belowHIH.value,
        },
      };
      this.toolsApi
        .updateSmartAlert(this.allData?.smartAlert._id, formData)
        .subscribe((res: any) => {
          this.flag = false;
          this.getSmartAlert();
        });
    }
  }
  onClickSetting() {
    this.isSetting = !this.isSetting;
  }
  save() {
    const formData = {
      earningDate: this.alertForm?.controls.earningDate.value,
      weeklySoldPutProfit: this.alertForm?.controls.weeklySoldPutProfit.value,
      longLegExpiration: this.alertForm?.controls.longLegExpiration.value,
      hihLine: {
        price: this.alertForm?.controls.price.value,
        aboveHIH: this.alertForm?.controls?.aboveHIH.value,
        belowHIH: this.alertForm?.controls?.belowHIH.value,
      },
      // notificationSetting: {
      //   push: this.settingForm?.controls.push.value,
      //   text: this.settingForm?.controls.text.value,
      //   mail: this.settingForm?.controls.mail.value,
      // },
    };
    this.toolsApi.updateSmartAlert(this.allData?.smartAlert._id, formData).subscribe((res: any) => {
      this.alertForm.reset();
      this.menuController.close('payload');
      this.menuService.setCloseMenuSubject(formData);
      this.cancel();
      this.toastServ.presentToast(res?.message, 'success');
    });
  }
  saveSetting() {
    const formData = {
      push: this.settingForm?.controls.push.value,
      text: this.settingForm?.controls.text.value,
      mail: this.settingForm?.controls.mail.value,
    };
    this.toolsApi
      .updateSmartAlertSetting(this.allData?.smartAlertSetting._id, formData)
      .subscribe((res: any) => {
        this.getSmartAlert();
        this.onClickSetting();
      });
  }
  onAlertClick() {
    this.cancel();
    this.router.navigate([`/${this.role}/settings`], {
      queryParams: { tab: 'settings' },
    });
  }
}
