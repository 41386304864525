import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MenuController } from '@ionic/angular';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';
import { CREATNOTEFOR, WEEKLYLEG, CREATNOTEFORMIT } from 'src/app/utils/constant';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  providers: [DatePipe],
})
export class NotesComponent implements OnInit {
  @Input() notesData: any;
  showForm = false;
  public tickerNotesForm: FormGroup;
  editSlug: string;
  noteData: any = [];
  CREATNOTEFOR = CREATNOTEFOR;
  CREATNOTEFORMIT = CREATNOTEFORMIT;
  WEEKLYLEG = WEEKLYLEG;
  selectedCreatefor: string | null = null;
  public toolNotesForm: FormGroup;
  isNew: any;
  weeklyLeg: any;
  longLeg: any;
  selectedLeg: any;
  isUpdate: boolean = false;
  isShowOverAll: boolean = false;
  stockeNotesData: any;
  MITStock: any;

  constructor(
    private formBuilder: FormBuilder,
    private toolService: ToolServiceService,
    private toastServ: ToastService,
    public menuController: MenuController,
    private menuService: commonRightMenuService,
    private datePipe: DatePipe
  ) {
    this.tickerNotesForm = this.formBuilder.group({
      description: ['', [Validators.required, this.noWhitespaceValidator]],
    });
  }

  ngOnInit() {
    this.isUpdate = false;
    this.getNotesList();
    this.isShowOverAll = false;
    this.menuService.getOpenMenuSubject().subscribe((data) => {
      this.showForm = false;
      this.notesData = data;
      this.editSlug = undefined;
      this.isShowOverAll = false;
      this.getNotesList();
    });
  }

  getNotesList() {
    this.noteData = [];
    this.stockeNotesData = [];
    this.weeklyLeg = this.notesData?.positionList?.filter(
      (x) => x.positionType == 'weekly' && x.parentSlug == ''
    );
    this.longLeg = this.notesData?.positionList?.filter(
      (x) => x.positionType == 'long' && x.parentSlug == ''
    );
    this.MITStock = this.notesData?.MITStockData?.mits;
    if (this.MITStock?.length > 0) {
      for (let i of this.MITStock) {
        i.name =
          '<span style="font-weight: bold;">MIT Stock</span> - ' +
          i.transactionType +
          ' | Shares:' +
          i.quantity;
        i.value = i._id;
      }
    }
    if (this.weeklyLeg.length > 0) {
      for (let i of this.weeklyLeg) {
        let parentSlug = this.notesData?.allData?.find((x) =>
          x.legs.find((x) => x.slug == i.slug || x.parentSlug == i.slug)
        ).parentSlug;
        i.name =
          '<span style="font-weight: bold;">Weekly Leg</span> - Expiration:' +
          this.datePipe.transform(i?.expirationPeriod, 'MM/dd/yyyy') +
          ' | Strike:' +
          i.strikeSold;
        i.value = i?.slug;
        i.newParentSlug = parentSlug;
      }
    }
    if (this.longLeg.length > 0) {
      for (let i of this.longLeg) {
        let parentSlug = this.notesData?.allData?.find((x) =>
          x.legs.find((x) => x.slug == i.slug || x.parentSlug == i.slug)
        ).parentSlug;
        i.name =
          'Long Leg - Expiration: ' +
          this.datePipe.transform(i?.expirationPeriod, 'MM/dd/yyyy') +
          ' | Strike: $' +
          i.strikeBought;
        i.value = i?.slug;
        i.newParentSlug = parentSlug;
      }
    }

    let slug = this.notesData.slug;
    this.toolService.getPositionNotes(slug).subscribe((res) => {
      this.noteData = res && res?.data && res.data.length ? res.data : [];
      if (this.noteData.length > 0) {
        this.noteData = this.noteData.filter((x) => x.desselectedLegcription != '');
        this.stockeNotesData = this.noteData.filter(
          (x) => x.type !== 'weekly' && x.type !== 'long' && x.type !== 'mit'
        );
        let filteredMITStock = [...this.MITStock];
        if (this.MITStock.length > 0) {
          for (let i of this.MITStock) {
            let list;
            list = this.noteData.find((x) => x.mit?._id == i._id);
            if (list) {
              const index = this.MITStock.indexOf(i);
              if (index !== -1) {
                filteredMITStock = filteredMITStock.filter((stock) => stock._id !== i._id);
              }
            }
          }
        }
        this.MITStock = filteredMITStock;
        if (this.weeklyLeg.length > 0) {
          for (let i of this.weeklyLeg) {
            let list;
            list = this.noteData.find((x) => x.slug == i.slug || x.parentSlug == i.newParentSlug);
            if (list) {
              const index = this.weeklyLeg.indexOf(i);
              if (index !== -1) {
                this.weeklyLeg.splice(index, 1);
              }
            }
          }
        }
        if (this.longLeg.length > 0) {
          for (let i of this.longLeg) {
            let list;
            list = this.noteData.find((x) => x.slug == i.slug || x.parentSlug == i.newParentSlug);
            if (list) {
              const index = this.longLeg.indexOf(i);
              if (index !== -1) {
                this.longLeg.splice(index, 1);
              }
            }
          }
        }
      }
    });
  }

  onNotesCreateSubmit() {
    if (this.tickerNotesForm.invalid) {
      this.toastServ.presentToast('Please provide a valid description.', 'danger');
      return;
    }

    // const description = this.tickerNotesForm.get('description').value;
    // const status = 'active';
    const slug = this.notesData.slug;
    let payload: any;
    //   tickerSlug: slug,
    //   status: status,
    //   description: description,
    // };
    let parentSlug;
    let type;
    if (this.selectedLeg?.parentSlug) {
      parentSlug = this.selectedLeg.parentSlug;
    } else {
      parentSlug = this.notesData.allData.find((x) =>
        x.legs.find((x) => x.slug == this.selectedLeg?.slug)
      )?.parentSlug;
    }
    if (this.selectedLeg?.positionType) {
      type = this.selectedLeg?.positionType;
    } else {
      type = this.selectedLeg?.type;
    }
    if (this.selectedCreatefor == 'mitstock' || this.selectedLeg?.type == 'mit') {
      payload = {
        tickerSlug: slug,
        description: this.tickerNotesForm.get('description').value,
        mitId: !!this.editSlug ? this.selectedLeg?.mit?._id : this.selectedLeg?._id,
        type: 'mit',
      };
    } else {
      payload = {
        tickerSlug: slug,
        description: this.tickerNotesForm.get('description').value,
        parentSlug: parentSlug,
        type: type === 'long' ? 'long' : type == 'weekly' ? 'weekly' : 'overall',
        expiration: this.selectedLeg?.expirationPeriod
          ? this.selectedLeg?.expirationPeriod
          : this.selectedLeg?.expiration,
        strike: this.selectedLeg?.strike
          ? this.selectedLeg?.strike
          : type == 'weekly'
          ? this.selectedLeg?.strikeSold
          : this.selectedLeg?.strikeBought,
      };
    }
    if (!this.editSlug) {
      if (this.selectedCreatefor == null) {
        this.toastServ.presentToast('Please select create note for.', 'danger');
        return;
      }
      if (this.selectedLeg == null && this.selectedCreatefor != 'overall') {
        let value =
          this.selectedCreatefor == 'weeklyput'
            ? 'Weekly Leg'
            : this.selectedCreatefor == 'mitstock'
            ? 'MIT Stock'
            : 'Protective Leg';
        this.toastServ.presentToast('Please select a ' + value + '.', 'danger');
        return;
      }
      this.toolService.createLegNotes(payload).subscribe((res: any) => {
        if (res) {
          this.tickerNotesForm.reset();
          this.showForm = false;
          this.toastServ.presentToast(res?.message, 'success');
          this.isUpdate = true;
          this.getNotesList();
        }
      });
    } else {
      this.toolService.updateLegNotes(this.selectedLeg.slug, payload).subscribe((res: any) => {
        if (res) {
          this.tickerNotesForm.reset();
          this.showForm = false;
          this.isUpdate = true;
          this.toastServ.presentToast(res?.message, 'success');
          this.getNotesList();
        }
      });
    }
  }

  toggleView(noteDetail?: any) {
    this.showForm = !this.showForm;
    this.editSlug = undefined;
    if (noteDetail) {
      this.editSlug = noteDetail.slug;
      this.tickerNotesForm.patchValue({
        description: noteDetail.description,
        status: 'active',
      });
      // this.selectedLeg =
      //   noteDetail.type == 'long'
      //     ? this.longLeg.find((x) => x.slug == noteDetail.slug || x.slug == noteDetail.parentSlug)
      //     : this.weeklyLeg.find(
      //         (x) => x.slug == noteDetail.slug || x.slug == noteDetail.parentSlug
      //       );
      this.selectedLeg = noteDetail;
    } else {
      this.tickerNotesForm.reset();
      // this.weeklyLeg = [];
      // this.longLeg = [];
      this.selectedLeg = null;
      this.selectedCreatefor = null;
    }
  }

  deleteNotes(noteDetail) {
    this.toastServ
      .confirmationAlert('Are you sure you want to delete this note?', 'Delete')
      .then((confirm) => {
        if (confirm) {
          this.toolService.deleteNote(noteDetail.slug).subscribe((response: any) => {
            this.toastServ.presentToast(response.message, 'success');
            this.getNotesList();
            this.isUpdate = true;
          });
        }
      });
  }

  cancel() {
    this.weeklyLeg = [];
    this.longLeg = [];
    this.MITStock = [];
    this.selectedLeg = null;
    this.selectedCreatefor = null;
    this.showForm = false;
    this.editSlug = undefined;
    this.menuController.close();
    if (this.isUpdate) {
      this.menuService.setCloseMenuSubject(this.isUpdate);
    }
  }
  // Custom validator to disallow only white spaces
  noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  }

  notesSlug(notesSlug: any, payload: any) {
    throw new Error('Method not implemented.');
  }
  onChangeLeg(event: any) {
    let value =
      typeof event.target.value == 'object' ? event.target.value.value : event.target.value;
    // if (!!event.target.value) {
    if (this.selectedCreatefor == 'mitstock') {
      this.selectedLeg = this.MITStock.find((x) => x._id == value);
    } else {
      this.selectedLeg =
        this.selectedCreatefor == 'weeklyput'
          ? this.weeklyLeg.find((x) => x.slug == value)
          : this.longLeg.find((x) => x.slug == value);
    }
    // }
  }
  changeToggle(event) {
    this.isShowOverAll = event && event.detail && event.detail.checked;
  }
}
