import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { Location } from '@angular/common';
import { Page } from 'src/app/models/page';
import { ActivatedRoute, Router } from '@angular/router';
import { TickerTradeModelComponent } from '../ticker-trade-model/ticker-trade-model.component';
import { ModalController } from '@ionic/angular';
import { ToolServiceService } from 'src/app/services/tool-service.service';
import { log } from 'console';
import { HttpLoaderService } from 'src/app/services/http-loader.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment';
import { TradeTrackersInsidersComponent } from '../trade-trackers-insiders/trade-trackers-insiders.component';
@Component({
  selector: 'app-product-tickers',
  templateUrl: './product-tickers.component.html',
  styleUrls: ['./product-tickers.component.scss'],
})
export class ProductTickersComponent implements OnInit, OnChanges {
  public role;
  activeToolDashboard = 'r6VAKez';
  public page = new Page();
  public searchText: string = null;
  reorderable: boolean = true;
  loadingIndicator: boolean = true;
  rows;
  @ViewChild('search', { static: false }) search: any;
  isOpen = false;
  posPnl: any;
  productSlug: any;
  toolSlug: any;
  tradeList: any;
  status: any;
  numberOfDays: number;
  providedDate: Date;
  currentDate: Date;
  sortDirection: 'asc' | 'desc' = 'asc';
  sortColumn: string = '';
  sortDirectionDate: string = 'asc';
  constructor(
    private route: ActivatedRoute,
    public storageServ: StorageService,
    private location: Location,
    private router: Router,
    private modalCtrl: ModalController,
    private toolsApi: ToolServiceService,
    private ngxService: NgxUiLoaderService
  ) {
    this.productSlug = route.snapshot.params.productSlug;
    this.toolSlug = route.snapshot.params.toolSlug;
    this.role = this.storageServ.get('role');
  }
  ngOnChanges(): void {
    this.getTradeList(this.productSlug, this.searchText, this.status);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params.toolSlug) {
        this.getTradeList(this.productSlug, this.searchText, this.status);
      }
    });
    this.getTradeList(this.productSlug, this.searchText, this.status);
  }
  onDeleteTrade() {
    window.location.reload();
  }
  back() {
    // this.location.back();
    this.router.navigate([`/${this.role}/tools/trade-trackers/${this.toolSlug}`]);
  }

  onClearSearch(event) {
    this.searchText = null;
  }

  onSearch(ev: CustomEvent) {
    this.searchText = ev.detail.value;
    this.getTradeList(this.productSlug, this.searchText, this.status);
  }

  getSavedSearches(page, disableHttpLoader = 'true') {}

  getSelectedRow({ type, row }: { type: string; row: any }) {
    localStorage.setItem('positionStatus', '');
    localStorage.setItem('trackerProductId', '');
    if (type === 'click') {
      localStorage.setItem('positionStatus', row.status);
      localStorage.setItem('trackerProductId', row.trackerProductId);
      this.router.navigate([
        `/${this.role}/tools/trade-trackers/${this.toolSlug}/product/${this.productSlug}/ticker/${row.slug}`,
      ]);
    }
  }
  async addTrade() {
    let access = localStorage.getItem('access');
    const isFullAccess = access === 'Full_Access';
    const isLiteAccess = access === 'Lite_Access';
    const isRegularAccess = access === 'Regular_Access';
    if (isFullAccess || (isLiteAccess && this.rows?.length < 3)) {
      const modal = await this.modalCtrl.create({
        component: TickerTradeModelComponent,
        cssClass: 'my-modal-class add-trade-modal',
        componentProps: {
          productSlug: this.productSlug,
          toolSlug: this.toolSlug,
        },
      });
      modal.onDidDismiss().then(() => {
        this.getTradeList(this.productSlug, this.searchText, this.status);
      });

      return await modal.present();
    } else {
      const modal = await this.modalCtrl.create({
        component: TradeTrackersInsidersComponent,
        cssClass: 'my-modal-class insider-modal',
        componentProps: { page: 'Trade Tracker' },
      });
      modal.onDidDismiss().then(() => {
        // this.getTradeList(this.productSlug, this.searchText, this.status);
      });

      return await modal.present();
    }
  }

  statusChange(status) {
    this.status = status.target.value;
    this.getTradeList(this.productSlug, this.searchText, this.status);
  }

  getTradeList(productSlug, searchText, status) {
    this.toolsApi.getTradeList(productSlug, searchText, status).subscribe((res: any) => {
      this.rows = res?.data?.tickerList || [];
    });
  }

  getHeaderClassDayPnL({ row }): any {
    return {
      'custom-cell-green': Number(row.stockPriceDayWise) >= 0,
      'custom-cell-red': Number(row.stockPriceDayWise) < 0,
      'custom-cell-black': Number(row.stockPriceDayWise) == 0,
    };
  }

  getHeaderClassCumulativeProfitLoss({ row }): any {
    return {
      'custom-cell-green': Number(row.cumulativeProfitLoss) > 0,
      'custom-cell-red': Number(row.cumulativeProfitLoss) < 0,
      'custom-cell-black': Number(row.cumulativeProfitLoss) == 0,
    };
  }

  getClassForEarningDays({ row }) {
    return {
      'custom-cell-green': row?.daysDifference >= 45,
      'custom-cell-darkgoldenrod': row?.daysDifference >= 30 && row?.daysDifference < 45,
      'custom-cell-red': row?.daysDifference < 30,
      'custom-cell-black': row?.status == 'closed',
    };
  }

  getStockPriceClass() {
    return {
      'custom-cell-stock-price': true,
    };
  }
  sortData(prop: string) {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'; // Toggle sorting direction

    this.rows = [
      ...this.rows.sort((a, b) => {
        if (this.sortDirection === 'asc') {
          return a[prop].localeCompare(b[prop]);
        } else {
          return b[prop].localeCompare(a[prop]);
        }
      }),
    ];
  }

  toggleSort() {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.rows = [
      ...this.rows.sort((a, b) => {
        return (a.stockPrice - b.stockPrice) * (this.sortDirection === 'asc' ? 1 : -1);
      }),
    ];
  }

  sortDataEarning() {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'; // Toggle sort direction
    this.rows = [
      ...this.rows.sort((a, b) => {
        const valueA = a.daysDifference || 0;
        const valueB = b.daysDifference || 0;

        if (this.sortDirection === 'asc') {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      }),
    ];
  }

  sortPnl() {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'; // Toggle sort direction
    this.rows = [
      ...this.rows.sort((a, b) => {
        const valueA = a.cumulativeProfitLoss || 0;
        const valueB = b.cumulativeProfitLoss || 0;

        if (this.sortDirection === 'asc') {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      }),
    ];
  }
}
