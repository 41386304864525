<div class="drawer-header header-drawer-custom">
  <div class="header-container">
    <div class="back-to">
      <a (click)="cancel()"
        ><ion-icon name="chevron-back-outline"></ion-icon>Back to Trade Trackers
      </a>
      <div class="close-block" (click)="cancel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </div>
    </div>
  </div>
</div>
<ion-header class="ion-no-border header-top-custom">
  <ion-toolbar>
    <div>
      <ion-title class="header-title">🧠 Trade Tracker Smart Alerts</ion-title>
      <span class="span-text">Stay Informed with Email, Text, and Push Notifications!</span>
    </div>
    <div class="close-block seeting-block" aria-disabled="isSetting" (click)="onAlertClick()">
      <ion-icon name="settings-outline"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content
  class="ion-padding-horizontal content-right-block add-stock-purchase-split no-contentbg"
>
  <div class="form-container" [formGroup]="alertForm">
    <ion-item
      lines="none"
      class="ion-item-no-inner-padding input-block-custom ion-no-padding width-css toggle-gray-sm"
    >
      <ion-label class="label-text">Within 30 days of Earning Date</ion-label>
      <ion-toggle
        lines="none"
        class="ion-no-padding"
        justify="end"
        formControlName="earningDate"
      ></ion-toggle>
    </ion-item>
    <ion-item
      lines="none"
      class="ion-item-no-inner-padding input-block-custom ion-no-padding width-css toggle-gray-sm"
    >
      <ion-label class="label-text">Weekly Sold Put - 90% Profit</ion-label>
      <ion-toggle
        lines="none"
        class="ion-no-padding"
        justify="end"
        formControlName="weeklySoldPutProfit"
      ></ion-toggle>
    </ion-item>
    <ion-item
      lines="none"
      class="ion-item-no-inner-padding input-block-custom ion-no-padding width-css toggle-gray-sm"
    >
      <ion-label class="label-text">Long leg is less than 30 days to expiration</ion-label>
      <ion-toggle
        lines="none"
        class="ion-no-padding"
        justify="end"
        formControlName="longLegExpiration"
      ></ion-toggle>
    </ion-item>
    <div class="bg-set-trade">
      <ion-item
        lines="none"
        class="ion-item-no-inner-padding input-block-custom ion-no-padding width-css toggle-gray-sm main-section-wraping"
      >
        <div class="left-sec">
          <div class="tooltip-info">
            <ion-label> HIH line</ion-label>
            <span class="tooltip">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.492 6.92414C8.46974 6.80615 8.40225 6.70043 8.30283 6.6278C8.20341 6.55517 8.07926 6.5209 7.95482 6.53174C7.83039 6.54257 7.71469 6.59773 7.63051 6.68634C7.54633 6.77496 7.49977 6.89061 7.5 7.01052V11.3312L7.508 11.4176C7.53026 11.5355 7.59775 11.6413 7.69717 11.7139C7.79659 11.7865 7.92074 11.8208 8.04518 11.81C8.16961 11.7991 8.28531 11.744 8.36949 11.6554C8.45367 11.5667 8.50023 11.4511 8.5 11.3312V7.01052L8.492 6.92414ZM8.799 4.85115C8.799 4.66025 8.71998 4.47717 8.57933 4.34218C8.43868 4.20719 8.24791 4.13136 8.049 4.13136C7.85009 4.13136 7.65932 4.20719 7.51867 4.34218C7.37802 4.47717 7.299 4.66025 7.299 4.85115C7.299 5.04205 7.37802 5.22513 7.51867 5.36012C7.65932 5.4951 7.85009 5.57094 8.049 5.57094C8.24791 5.57094 8.43868 5.4951 8.57933 5.36012C8.71998 5.22513 8.799 5.04205 8.799 4.85115ZM16 7.97024C16 5.93397 15.1571 3.9811 13.6569 2.54124C12.1566 1.10138 10.1217 0.29248 8 0.29248C5.87827 0.29248 3.84344 1.10138 2.34315 2.54124C0.842855 3.9811 0 5.93397 0 7.97024C0 10.0065 0.842855 11.9594 2.34315 13.3992C3.84344 14.8391 5.87827 15.648 8 15.648C10.1217 15.648 12.1566 14.8391 13.6569 13.3992C15.1571 11.9594 16 10.0065 16 7.97024ZM1 7.97024C1 7.08801 1.18106 6.21443 1.53284 5.39936C1.88463 4.58429 2.40024 3.8437 3.05025 3.21987C3.70026 2.59604 4.47194 2.10119 5.32122 1.76358C6.1705 1.42597 7.08075 1.2522 8 1.2522C8.91925 1.2522 9.8295 1.42597 10.6788 1.76358C11.5281 2.10119 12.2997 2.59604 12.9497 3.21987C13.5998 3.8437 14.1154 4.58429 14.4672 5.39936C14.8189 6.21443 15 7.08801 15 7.97024C15 9.75197 14.2625 11.4607 12.9497 12.7206C11.637 13.9805 9.85652 14.6883 8 14.6883C6.14348 14.6883 4.36301 13.9805 3.05025 12.7206C1.7375 11.4607 1 9.75197 1 7.97024Z"
                  fill="#000032"
                  fill-opacity="0.39"
                  strikeBought
                />
              </svg>
              <p class="tooltiptext">
                Enter the price level that marks your HIH line-If the stock drops below this, it
                could signal a bigger move down. Use this to track key support levels and plan your
                trades accordingly.
              </p>
            </span>
          </div>
        </div>
        <div class="right-sec">
          <div class="input-block-custom custom-opacity">
            <div class="underlyingprice-left">
              <span class="dor">$</span>
              <ion-input type="number" formControlName="price" min="0"></ion-input>
            </div>
          </div>

          <ion-button
            class="blue-btn ion-no-padding"
            color="primary"
            type="submit"
            fill="solid"
            (click)="saveHIH()"
          >
            SAVE
          </ion-button>
          <div class="error-mesg-showing">
            <ng-container *ngIf="alertForm.invalid && alertForm.touched">
              <!-- <span class="error" *ngIf="errorControl.price.errors?.required">
                Price is required field.
              </span> -->
              <span class="error" *ngIf="errorControl.price.errors?.max">
                You can only add value below ${{ this.alertsData?.stockPrice }}*
              </span>
            </ng-container>
          </div>
        </div>
      </ion-item>

      <ion-item
        lines="none"
        class="ion-item-no-inner-padding input-block-custom ion-no-padding width-css toggle-gray-sm toogle"
        [disabled]="flag"
      >
        <ion-label>Stock price is 20% above 'HIH' line</ion-label>

        <div>
          <ion-toggle
            lines="none"
            class="ion-no-padding"
            justify="end"
            formControlName="aboveHIH"
          ></ion-toggle>
        </div>
      </ion-item>
      <ion-item
        lines="none"
        class="ion-item-no-inner-padding input-block-custom ion-no-padding width-css toggle-gray-sm"
        [disabled]="flag"
      >
        <ion-label> Stock price is below 'HIH' line </ion-label>
        <div>
          <ion-toggle
            lines="none"
            class="ion-no-padding"
            justify="end"
            formControlName="belowHIH"
          ></ion-toggle>
        </div>
      </ion-item>
    </div>
    <ion-footer class="ion-no-border">
      <ion-toolbar>
        <ion-button type="submit" color="primary" fill="solid" style="width: 100%" (click)="save()">
          SAVE ALERTS
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </div>
  <!-- <div class="notification-wrap" *ngIf="isSetting">
    <div class="block-container" [formGroup]="settingForm">
      <div class="block-label">
        <ion-icon name="notifications-outline"></ion-icon>
        <label>Trade Tracker Alert Notifications</label>
      </div>
      <div class="block-content">
        <div class="checkbox-item-block">
          <ion-checkbox slot="start" color="primary" formControlName="mail"></ion-checkbox>
          <label>Email</label>
        </div>
        <div class="checkbox-item-block">
          <ion-checkbox slot="start" color="primary" formControlName="push"></ion-checkbox>
          <label>In App Notification</label>
        </div>
        <div class="checkbox-item-block">
          <ion-checkbox slot="start" color="primary" formControlName="text"></ion-checkbox>
          <label>Text</label>
        </div>
      </div>
    </div>
    <ion-buttons slot="primary" class="button-group-all">
      <ion-button fill="outline" color="medium" class="cancel-btn" (click)="onClickSetting()">
        Cancel
      </ion-button>
      <ion-button fill="solid" color="primary" class="cancel-btn back-with" (click)="saveSetting()">
        Save
      </ion-button>
    </ion-buttons>
  </div> -->
</ion-content>
