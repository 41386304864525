import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { StorageService } from '../../../services/storage.service';
import { ProfileService } from '../../../services/profile.service';
import { ToastService } from '../../../services/toast.service';
import { ThemeService } from 'src/app/services/theme.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { ActivatedRoute } from '@angular/router';
import { ToolServiceService } from 'src/app/services/tool-service.service';
@Component({
  selector: 'app-user-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {
  public userDetails: any;
  public image: File;
  public imagePreview: any;
  public activeTab = 'profile';
  public profileForm: FormGroup;

  public accountSizeForm: FormGroup;
  public changePasswordForm: FormGroup;
  public profileSettingForm: FormGroup;
  public themes = [
    { name: 'Default', value: 'theme-default' },
    { name: 'Bootstrap', value: 'theme-bootstrap' },
    { name: 'Material', value: 'theme-material' },
    { name: 'Ionic', value: 'theme-ionic' },
  ];
  public customPopoverOptions: any = {
    showBackdrop: false,
  };
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  phoneNumber: string = '';
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  enableContactInput = false;
  countryCode = 'us';
  constructor(
    private location: Location,
    private profileServ: ProfileService,
    private storageserv: StorageService,
    private toastServ: ToastService,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private toolsApi: ToolServiceService
  ) {
    const user: any = JSON.parse(this.storageserv.get('userData') || '{}');
    this.userDetails = user;
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams?.tab && queryParams.tab === 'settings') {
        this.activeTab = queryParams.tab;
      }
      if (queryParams?.tab && queryParams.tab === 'account-size') {
        this.activeTab = queryParams.tab;
      }
    });
    this.profileForm = this.formBuilder.group({
      firstName: new FormControl(
        user?.firstName || '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern('^[A-Za-z -]+$'),
        ])
      ),
      lastName: new FormControl(
        user?.lastName || '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern('^[A-Za-z -]+$'),
        ])
      ),
      email: new FormControl(
        user?.email || '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z0-9._%!+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$'),
        ])
      ),
      theme: new FormControl(user?.email || 'theme-default'),
    });

    this.profileSettingForm = this.formBuilder.group({
      notifications: new FormGroup({
        comment: new FormGroup({
          email: new FormControl(false),
          webPush: new FormControl(true),
          nativePush: new FormControl(false),
          inApp: new FormControl(false),
        }),
        chat: new FormGroup({
          email: new FormControl(false),
          webPush: new FormControl(true),
          nativePush: new FormControl(false),
          inApp: new FormControl(false),
        }),
        alert: new FormGroup({
          email: new FormControl(false),
          nativePush: new FormControl(false),
          text: new FormControl(false),
          contact: new FormControl('', [Validators.required]),
        }),

        smartAlert: new FormGroup({
          push: new FormControl(false),
          text: new FormControl(false),
          mail: new FormControl(false),
        }),

        // broadcast: new FormGroup({
        //   email: new FormControl(false),
        //   webPush: new FormControl(true),
        //   nativePush: new FormControl(false),
        //   inApp: new FormControl(false),
        // }),
      }),
    });
    this.accountSizeForm = this.formBuilder.group({
      accountSize: new FormControl(user?.accountSize || '', Validators.required),
    });
    this.changePasswordForm = this.formBuilder.group(
      {
        currentPassword: new FormControl('', Validators.required),
        password: new FormControl(
          '',
          Validators.compose([Validators.required, Validators.minLength(6)])
        ),
        confirmpassword: new FormControl(
          '',
          Validators.compose([Validators.required, Validators.minLength(6)])
        ),
      },
      {
        validators: this.comparePassword.bind(this),
      }
    );
  }

  ngOnInit() {
    this.getUserDetails();
  }

  checkContact() {
    const notificationControl = this.profileSettingForm.get('notifications').value;
    this.enableContactInput = !notificationControl?.alert.text;
    const alertControl = this.profileSettingForm.get('notifications.alert');
    if (this.enableContactInput) {
      alertControl.get('contact').setValidators([Validators.required]);
    } else {
      alertControl.get('contact').clearValidators();
    }
    alertControl.get('contact').updateValueAndValidity();
  }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }

  async getUserDetails() {
    this.profileService.fetchProfile().subscribe((response: any) => {
      const { user = null } = response;

      if (user) {
        this.userDetails = user;
        this.imagePreview = user?.image;

        this.profileForm.patchValue({
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          email: user?.email || '',
          theme: user?.theme,
        });

        this.profileSettingForm.patchValue({
          notifications: user?.notificationSettings,
        });

        if (user?.notificationSettings?.alert?.text) {
          this.enableContactInput = true;
          this.countryCode = user?.countryCode || 'us';
          this.profileSettingForm.patchValue({
            notifications: {
              alert: {
                contact: user?.contact, // Set the value you want to patch
              },
            },
          });
        }

        this.accountSizeForm.patchValue({
          accountSize: user?.accountSize || '',
        });

        this.activateTheme();
      }
    });
  }

  activateTheme() {
    this.themeService.activeTheme(this.profileForm.value?.theme || 'theme-default');
  }

  get getUserName() {
    return (
      (this.profileForm?.value?.firstName || '') + ' ' + (this.profileForm?.value?.lastName || '')
    );
  }

  get formControls() {
    return this.profileForm.controls;
  }

  get changePasswordFormControls() {
    return this.changePasswordForm.controls;
  }
  get accountSizeFormControls() {
    return this.accountSizeForm.controls;
  }

  async onProfileImageChange(fileData) {
    this.image = fileData.fileToUpload;
    this.imagePreview = fileData.imageUrl;
  }

  onSubmit() {
    const { email, firstName, lastName, theme } = this.profileForm.value;

    const formData = new FormData();
    formData.append('image', this.image ? this.image : '');
    formData.append('email', email);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('theme', theme);

    this.profileServ.updateProfile(formData).subscribe((response: any) => {
      if (response) {
        const { message = 'Ok', user } = response;
        this.toastServ.presentToast(message, 'success');
        this.storageserv.set('userData', JSON.stringify(user));
        this.profileServ.tickForLoadProfile(true);
        this.getUserDetails();
      }
    });
  }

  onSettingSave() {
    let clone = this.profileSettingForm.value;
    const contactNumber = clone?.notifications?.alert?.contact;
    /*   delete clone?.notifications?.alert?.contact; */
    if (clone?.notifications?.alert?.text) {
      clone.contact = contactNumber?.number;
      clone.countryCode = contactNumber?.countryCode.toLowerCase();
    }
    const formData = {
      push: clone?.notifications?.smartAlert.push,
      text: clone?.notifications?.smartAlert.text,
      mail: clone?.notifications?.smartAlert.mail,
    };
    this.toolsApi
      .updateSmartAlertSetting(this.userDetails?.notificationSettings?.smartAlert?._id, formData)
      .subscribe((res: any) => {});
    this.profileServ
      .updateNotificationSetting(this.profileSettingForm.value)
      .subscribe((response: any) => {
        if (response) {
          const { message = 'Ok' } = response;
          this.toastServ.presentToast(message, 'success');
        }
      });
  }

  changePassword() {
    const passwords = {
      currentPassword: this.changePasswordForm.value.currentPassword,
      newPassword: this.changePasswordForm.value.confirmpassword,
    };

    this.profileServ.changePassword(passwords).subscribe((response: any) => {
      if (response) {
        const { message = 'Ok' } = response.data;
        this.toastServ.presentToast(message, 'success');
      }
    });
  }

  submitAccountSize() {
    const accountSizeClone = {
      accountSize: this.accountSizeForm.value.accountSize,
    };
    this.profileServ.accountSize(accountSizeClone).subscribe((response: any) => {
      if (response) {
        const { message = 'Ok' } = response.data;
        this.toastServ.presentToast(message, 'success');
      }
    });
  }

  back() {
    this.location.back();
  }

  comparePassword(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmpassword');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  segmentChanged(ev: any) {}
}
