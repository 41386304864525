<div class="drawer-header header-drawer-custom">
  <div class="header-container">
    <div class="back-to">
      <a (click)="cancel()"><ion-icon name="chevron-back-outline"></ion-icon>Back to profile </a>
      <div class="close-block" (click)="cancel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </div>
    </div>
  </div>
</div>

<ion-header class="ion-no-border header-top-custom">
  <ion-toolbar>
    <ion-title>{{ formTitle }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-horizontal content-right-block add-stock-purchase-split">
  <div class="form-container" [formGroup]="addMITStock">
    <div class="input-block-custom custom-opacity bg-date-icon">
      <ion-label *ngIf="formType === 'buy'" position="floating">Date Purchased</ion-label>
      <ion-label *ngIf="formType === 'sell'" position="floating">Date Sold</ion-label>

      <ion-input
        class="border-set"
        formControlName="purchasedDate"
        placeholder="MM/DD/YYYY"
        (click)="openDatePicker()"
        (focus)="openDatePicker()"
        id="open-modal"
      ></ion-input>
      <ion-modal class="datetime-modal" trigger="open-modal">
        <ng-template>
          <ion-content>
            <ion-datetime
              presentation="date"
              (ionChange)="closeDatePicker($event)"
              displayFormat="MM/DD/YYYY"
              pickerFormat="MM DD YYYY"
              [showDefaultButtons]="true"
            ></ion-datetime>
          </ion-content>
        </ng-template>
      </ion-modal>
    </div>

    <div class="input-block-custom custom-opacity">
      <ion-label position="floating" *ngIf="formType === 'buy'"># Of Shares</ion-label>
      <ion-label position="floating" *ngIf="formType === 'sell'">Shares Sold</ion-label>
      <ion-input
        type="number"
        inputmode="decimal"
        min="0"
        formControlName="quantity"
        class="border-set"
      ></ion-input>
    </div>
    <div class="input-block-custom custom-opacity">
      <ion-label position="floating" *ngIf="formType === 'buy'">Purchase Price</ion-label>
      <ion-label position="floating" *ngIf="formType === 'sell'">Sold Price</ion-label>
      <div class="underlyingprice-left">
        <span class="dor">$</span>
        <ion-input
          type="number"
          inputmode="decimal"
          min="0"
          formControlName="price"
          class="border-set"
        ></ion-input>
      </div>
    </div>
    <ion-item class="ion-item-no-inner-padding checkbox-add" lines="none">
      <ion-checkbox
        color="primary"
        slot="start"
        (ionChange)="onaddNoteSelect($event)"
        formControlName="notesCheck"
      ></ion-checkbox>
      <ion-label>Add Note</ion-label>
    </ion-item>
    <div class="input-block-custom" *ngIf="isaddNote">
      <label for="notes">Notes</label>
      <textarea class="notes-description" formControlName="noteDes" rows="4" cols="50"></textarea>
    </div>
    <ion-footer class="ion-no-border">
      <ion-toolbar>
        <ion-button
          type="submit"
          color="primary"
          fill="solid"
          [disabled]="addMITStock?.invalid"
          style="width: 100%"
          (click)="onSubmit()"
        >
          {{
            formType === 'buy'
              ? mode == 'edit'
                ? 'EDIT STOCK PURCHASE'
                : 'ADD STOCK PURCHASE'
              : mode == 'edit'
              ? 'EDIT STOCK SOLD'
              : 'SAVE STOCK SOLD'
          }}
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </div>
</ion-content>
