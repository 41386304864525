<ion-content class="ion-padding app-ticker-details" appScrollbar>
  <ol class="cd-breadcrumb custom-separator custom-icons">
    <li><a routerLink="/{{ role }}/tools/dashboard/{{ activeToolDashboard }}">Tools</a></li>
    <li>
      <a routerLink="/{{ role }}/tools/trade-trackers/{{ toolSlug }}">Trade Trackers</a>
    </li>
    <li>
      <a (click)="back()">{{
        productSlug == 'h37CPml' ? 'Master Income Trader' : 'Money Press Method'
      }}</a>
    </li>
    <li class="current" *ngIf="stockDetailRow && stockDetailRow?.tickerName">
      {{ stockDetailRow?.tickerName | titlecase }}
    </li>
  </ol>

  <div class="row-page-heading trade-row-heading">
    <div class="row">
      <span class="back-trade" (click)="back()">
        {{
          productSlug == 'h37CPml'
            ? '< Back To Master Income Trade Trackers'
            : '< Back To Money Press Trade Trackers'
        }}</span
      >
    </div>
    <div class="page-heading">
      <div class="actions">
        <ion-buttons>
          <ion-button (click)="openNotification($event)" color="medium" class="notification-button">
            <!-- <ion-icon slot="icon-only" name="notifications-outline"></ion-icon> -->
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.125 5.72933L5.20833 3.646M21.875 5.72933L19.7917 3.646M12.5 8.85433V13.021L14.5833 15.1043M20.8333 13.021C20.8333 17.6234 17.1024 21.3543 12.5 21.3543C7.89762 21.3543 4.16667 17.6234 4.16667 13.021C4.16667 8.41862 7.89762 4.68766 12.5 4.68766C17.1024 4.68766 20.8333 8.41862 20.8333 13.021Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect x="17" y="6" width="7" height="7" rx="3.5" fill="#05ADDE" />
            </svg>
          </ion-button>
          <ion-label
            class="hih-text"
            *ngIf="
              (hihLineData?.aboveHIH || hihLineData?.belowHIH) &&
              !!hihLineData?.price &&
              positionStatus !== 'closed'
            "
          >
            <span class="hih-span"
              >HIH Line Set: <span class="price-span"> ${{ hihLineData?.price }}</span></span
            ></ion-label
          >
          <ion-button
            class="border-style"
            (click)="openRightMenuNotes()"
            color="primary"
            fill="solid"
          >
            <ion-icon slot="start" name="document-text-outline"></ion-icon>
            <span>{{ stockDetailRow?.notesCount }} Notes</span>
          </ion-button>

          <div class="more-btn">
            <ion-button class="more-btn" (click)="toggleDropdown($event, row)">
              <img src="../../../../../../assets/icon/more.png" />
            </ion-button>
            <div class="dropdown-menu dropdown-content" *ngIf="isDropdownOpen">
              <!-- <svg -->
              <!-- class="svg-bg" -->
              <!-- width="174" -->
              <!-- height="80" -->
              <!-- viewBox="0 0 174 80" -->
              <!-- fill="none" -->
              <!-- xmlns="http://www.w3.org/2000/svg" -->
              <!-- > -->
              <!-- <mask id="path-1-inside-1_4123_4235" fill="white"> -->
              <!-- <path -->
              <!-- fill-rule="evenodd" -->
              <!-- clip-rule="evenodd" -->
              <!-- d="M154.295 9L147.5 0L140.705 9H4C1.79086 9 0 10.7909 0 13V76C0 78.2091 1.79086 80 4 80H170C172.209 80 174 78.2091 174 76V13C174 10.7909 172.209 9 170 9H154.295Z" -->
              <!-- /> -->
              <!-- </mask> -->
              <!-- <path -->
              <!-- fill-rule="evenodd" -->
              <!-- clip-rule="evenodd" -->
              <!-- d="M154.295 9L147.5 0L140.705 9H4C1.79086 9 0 10.7909 0 13V76C0 78.2091 1.79086 80 4 80H170C172.209 80 174 78.2091 174 76V13C174 10.7909 172.209 9 170 9H154.295Z" -->
              <!-- fill="white" -->
              <!-- /> -->
              <!-- <path -->
              <!-- d="M147.5 0L148.298 -0.602549L147.5 -1.65962L146.702 -0.602549L147.5 0ZM154.295 9L153.497 9.60255L153.797 10H154.295V9ZM140.705 9V10H141.203L141.503 9.60255L140.705 9ZM146.702 0.602549L153.497 9.60255L155.093 8.39745L148.298 -0.602549L146.702 0.602549ZM141.503 9.60255L148.298 0.602549L146.702 -0.602549L139.907 8.39745L141.503 9.60255ZM4 10H140.705V8H4V10ZM1 13C1 11.3431 2.34315 10 4 10V8C1.23858 8 -1 10.2386 -1 13H1ZM1 76V13H-1V76H1ZM4 79C2.34315 79 1 77.6569 1 76H-1C-1 78.7614 1.23857 81 4 81V79ZM170 79H4V81H170V79ZM173 76C173 77.6569 171.657 79 170 79V81C172.761 81 175 78.7614 175 76H173ZM173 13V76H175V13H173ZM170 10C171.657 10 173 11.3431 173 13H175C175 10.2386 172.761 8 170 8V10ZM154.295 10H170V8H154.295V10Z" -->
              <!-- fill="#B3B3B3" -->
              <!-- mask="url(#path-1-inside-1_4123_4235)" -->
              <!-- /> -->
              <!-- </svg> -->
              <div class="arrow"></div>
              <ion-item
                id=""
                (click)="showTradeDelete()"
                lines="none"
                [disabled]="!disableCloseTrade"
                class="first ion-no-padding width-css dropdown-item"
                >Close Trade</ion-item
              >
              <ion-item
                id=""
                (click)="openRightMenuTraders()"
                lines="none"
                class="second ion-no-padding width-css dropdown-item"
                >Trade History</ion-item
              >
              <ion-item
                id=""
                (click)="showTradeDeleteConfirmation()"
                lines="none"
                class="first ion-no-padding width-css dropdown-item"
                >Delete Trade</ion-item
              >
            </div>
          </div>

          <!-- <ion-button class="border-style" (click)="openRightMenu()" color="primary" fill="solid"> -->
          <!-- <ion-icon slot="start" name="notifications-outline"></ion-icon> -->
          <!-- <span>{{ stockDetailRow?.alertsCount }} Alerts</span> -->
          <!-- </ion-button> -->
        </ion-buttons>
      </div>
    </div>
  </div>
  <ion-row>
    <ion-col size-xs="12" size-sm="12" size-md="12" size-xl="8" size-lg="8">
      <div class="share-details">
        <ion-card class="ion-no-margin ion-margin-bottom">
          <ion-card-content>
            <div class="share">
              <div class="share-name">
                <div class="title">
                  <!-- <h1 style="color: #4f4f4f; font-weight: 700; margin-bottom: 0"></h1> -->
                  <ion-card-title *ngIf="stockDetailRow && stockDetailRow.tickerName">
                    {{ stockDetailRow?.tickerName }} ({{ stockDetailRow?.symbol }} )
                    <div class="subtitle-text">NasdaqGS: {{ stockDetailRow?.tickerName }}</div>
                  </ion-card-title>
                </div>
                <!-- <div class="product-status" *ngIf="productSlug == 'h37CPml'"> -->
                <div class="product-status">
                  <ion-chip *ngIf="positionStatus == 'closed'" [outline]="true" class="closed">
                    <ion-text>
                      {{ positionStatus | uppercase }}
                    </ion-text>
                  </ion-chip>
                  <ion-chip *ngIf="positionStatus !== 'closed'" [outline]="true" class="open">
                    <ion-text>
                      {{ positionStatus | uppercase }}
                    </ion-text>
                  </ion-chip>
                  <!-- <div class="status-dot"
                  [ngClass]="{
                    'dot-position-close': row[0]?.status == 'closed',
                    'dot-position-open': row[0]?.status == 'open'
                  }">{{ row[0]?.status }}</div> -->
                </div>
                <!-- <div class="action" *ngIf="stockDetailRow?.stockUrl">
                  <span class="logo"
                    ><img [src]="stockDetailRow?.stockUrl" alt="" srcset=""
                  /></span>
                </div> -->
                <div class="action" *ngIf="iframeSrc && !stockDetailRow?.stockUrl">
                  <!-- <span class="logo log-dynamic"><img [src]="iframeSrc" alt="" srcset="" /></span> -->
                </div>
              </div>
              <div
                class="ticker-description-container ion-flex-container ion-align-items-left ion-flex-wrap"
              >
                <div
                  class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column"
                >
                  <span>INDUSTRY:</span>
                  <span>{{ stockDetailRow?.industry }}</span>
                </div>
                <span class="desc-divider"></span>
                <div
                  class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column"
                >
                  <span>SECTOR:</span>
                  <span>{{ stockDetailRow?.sector }}</span>
                </div>
                <span class="desc-divider"></span>
                <div
                  class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column"
                >
                  <span>CURRENT PRICE:</span>
                  <div
                    *ngIf="stockDetailRow && stockDetailRow.stockPrice"
                    class="ion-flex-container ion-align-items-left ion-flex-gap-default"
                  >
                    <span>{{ stockDetailRow?.stockPrice | currency }}</span>
                  </div>
                </div>
                <span class="desc-divider"></span>
                <!-- <div -->
                <!-- class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column" -->
                <!-- > -->
                <!-- <span>EARNING DATE:</span> -->
                <!-- <span>{{ tradeEarningsDate }}</span> -->
                <!-- </div> -->
                <div
                  class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column"
                >
                  <span>TODAY'S CHANGE:</span>
                  <div class="ion-flex-container ion-align-items-left ion-flex-gap-default">
                    <ng-container *ngIf="stockDetailRow?.regularMarketChange > 0">
                      <ion-icon name="arrow-up" color="primary" style="font-size: 20px"></ion-icon>
                    </ng-container>
                    <ng-container *ngIf="stockDetailRow?.regularMarketChange < 0">
                      <ion-icon name="arrow-down" color="danger" style="font-size: 20px"></ion-icon>
                    </ng-container>
                    <span [ngClass]="getHeaderClassTodayChange({ stockDetailRow: stockDetailRow })"
                      >{{ stockDetailRow?.regularMarketChange | currency }}({{
                        stockDetailRow?.marketChangePercentage
                      }}%)</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </ion-card-content>
        </ion-card>
        <div class="profit-n-loss">
          <div class="profit">
            <ion-card class="ion-no-margin">
              <ion-card-content class="ion-no-padding">
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-xl="6" size-lg="12"
                      ><span class="today-pnl-title">Earnings Date:</span></ion-col
                    >
                    <ion-col
                      [ngClass]="getClassForEarningDays({ stockDetailRow: stockDetailRow })"
                      size-xs="12"
                      size-sm="12"
                      size-md="12"
                      size-xl="6"
                      size-lg="12"
                    >
                      <span
                        class="earning-date-value"
                        *ngIf="stockDetailRow?.earningStartDate != stockDetailRow?.earningEndDate"
                      >
                        <span>
                          ~ {{ stockDetailRow?.earningStartDate }}

                          <div
                            class="tooltip"
                            *ngIf="
                              stockDetailRow?.earningStartDate != stockDetailRow?.earningEndDate
                            "
                          >
                            <ion-icon name="alert-circle"></ion-icon>
                            <p class="tooltiptext">
                              The earnings date is currently an estimate. As the date gets closer
                              the actual earnings date will be confirmed.
                            </p>
                          </div>
                        </span>
                        <p>{{ stockDetailRow?.daysDifference }} days</p>
                      </span>

                      <span
                        class="earning-date-value"
                        *ngIf="stockDetailRow?.earningStartDate == stockDetailRow?.earningEndDate"
                      >
                        <span>
                          <ion-icon name="checkmark-circle"></ion-icon
                          >{{ stockDetailRow?.earningStartDate }}
                        </span>
                        <p>{{ stockDetailRow?.daysDifference }} days</p></span
                      ></ion-col
                    >
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </div>
          <div class="loss">
            <ion-card class="ion-no-margin">
              <ion-card-content class="ion-no-padding">
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-xl="6" size-lg="12"
                      ><span class="today-pnl-title"
                        >Position P&L
                        <a
                          *ngIf="productSlug == 'h37CPml'"
                          style="cursor: pointer"
                          (click)="onClickPnL()"
                          ><span>View Details</span></a
                        >
                        <p *ngIf="productSlug !== 'h37CPml'">Return On Margin</p></span
                      ></ion-col
                    >
                    <ion-col
                      [ngClass]="
                        getHeaderClassPositionPnL({ row: { totalProfitLoss: calculatePnL() } })
                      "
                      size-xs="12"
                      size-sm="12"
                      size-md="12"
                      size-xl="6"
                      size-lg="12"
                    >
                      <span class="earning-date-value" *ngIf="isIntervalLoader"
                        ><ion-spinner name="dots"></ion-spinner
                      ></span>
                      <!-- <span *ngIf="!isIntervalLoader" class="today-pnl-value">
                        {{ calculatePnL() | currency }}
                        <p>{{ calculatePnLMargin().toFixed(2) }} %</p></span
                      > -->
                      <span *ngIf="!isIntervalLoader" class="today-pnl-value">
                        <span>{{ calculatePnL() | currency }}</span>
                        <p *ngIf="productSlug !== 'h37CPml'">
                          {{ calculatePnLMargin().toFixed(2) }} %
                        </p></span
                      >
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </div>
        </div>
      </div>
    </ion-col>
    <ion-col class="left-col" size-xs="12" size-sm="12" size-md="12" size-xl="4" size-lg="4">
      <div class="account-details ion-no-padding">
        <ion-card class="ion-no-margin" style="height: 100%">
          <ion-card-content class="ion-no-padding">
            <ion-list lines="full" [inset]="true" class="list-ticker-portfolio-details">
              <ion-item>
                <div class="detail-container">
                  <div class="details">
                    <div class="label-icon">
                      <span>ACCOUNT SIZE:</span>
                      <div class="icon-div" (click)="accountSizeRedirect()">
                        <ion-icon slot="icon-only" name="create-outline"></ion-icon>
                      </div>
                    </div>
                    <span class="account-value">{{ row?.accountSize | currency }}</span>
                  </div>
                </div>
              </ion-item>
              <ion-item>
                <div class="detail-container">
                  <div *ngIf="productSlug !== 'h37CPml'" class="details">
                    <span>MARGIN:</span>
                    <!-- <span></span> -->
                    <span>{{ row?.margin | currency }}</span>
                  </div>
                  <div *ngIf="productSlug == 'h37CPml'" class="details">
                    <span>POSITION EXPOSURE:</span>
                    <span>{{
                      MITStockData?.positionExposure
                        ? (MITStockData?.positionExposure | currency)
                        : (0.0 | currency)
                    }}</span>
                  </div>
                </div>
              </ion-item>
              <ion-item>
                <div class="detail-container">
                  <div class="details">
                    <span>PORTFOLIO EXPOSURE :</span>
                    <!-- <span></span> -->
                    <span *ngIf="productSlug !== 'h37CPml'"
                      >{{ row?.portpholioRisk?.toFixed(2) }}%</span
                    >
                    <span *ngIf="productSlug == 'h37CPml'"
                      >{{
                        MITStockData?.portfolioExposure
                          ? MITStockData?.portfolioExposure?.toFixed(2)
                          : 0.0
                      }}%</span
                    >
                  </div>
                </div>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-col>
  </ion-row>

  <div *ngIf="productSlug == 'h37CPml'">
    <div class="page-heading ion-padding-top">
      <h2>MIT Stock</h2>
      <div class="actions">
        <ion-buttons>
          <ion-button
            class="border-style"
            color="primary"
            fill="solid"
            (click)="stockAddUpdate('buy', 'add')"
          >
            <span>BUY STOCK</span>
          </ion-button>
          <ion-button
            class="border-style"
            color="danger"
            fill="solid"
            (click)="stockAddUpdate('sell', 'add')"
          >
            <span>SELL STOCK</span>
          </ion-button>
        </ion-buttons>
      </div>
    </div>

    <ion-row class="ion-justify-content-center ion-mit-stock-grid">
      <div class="data-box">
        <div class="label-mit">
          <span>OPEN SHARES</span>
        </div>
        <span class="span-value">{{ MITStockData?.shares }}</span>
      </div>

      <div class="data-box">
        <div class="label-mit">
          <span>AVERAGE COST PER SHARES</span>
        </div>
        <span class="span-value">${{ MITStockData?.averageCostPerShare || 0 }}</span>
      </div>

      <!-- <div class="p-018"> -->
      <div class="data-box">
        <div class="label-mit">
          <span>COST BASIS</span>
        </div>
        <span class="span-value">${{ MITStockData?.costBasis || 0 }}</span>
      </div>
      <!-- </div> -->

      <!-- <div class="p-018"> -->
      <div class="data-box">
        <div class="label-mit">
          <span class="gain-stock">STOCK GAIN/LOSS</span>
        </div>
        <span class="span-value">{{ calculateGainLoss() | currency }} </span>
        <div>
          <a style="cursor: pointer" (click)="onClickSTOCK()"><span>View Details</span></a>
        </div>
      </div>
      <!-- </div> -->
    </ion-row>

    <ion-card class="ion-no-margin card-container" style="margin-top: 18px" *ngIf="isShowStocks">
      <ion-card-content class="ion-no-padding">
        <ngx-datatable
          external-analytics
          #externalAnalyticsTable
          class="material body-cell-stacked ticker-details-table"
          [rows]="MITStockData?.mits"
          [columnMode]="'force'"
          [rowHeight]="'force'"
          [headerHeight]="50"
          [footerHeight]="50"
          [scrollbarH]="true"
        >
          <ngx-datatable-column [width]="104" [sortable]="false" [resizeable]="false">
            <ng-template ngx-datatable-header-template>DATE</ng-template>
            <ng-template
              let-row="row"
              let-purchasedDate="row.purchasedDate"
              ngx-datatable-cell-template
            >
              {{ purchasedDate | date : 'MM/dd/yyyy' : 'UTC' }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [width]="110" [sortable]="true" [resizeable]="false">
            <ng-template ngx-datatable-header-template> SIDE </ng-template>
            <ng-template
              let-row="row"
              let-transactionType="row.transactionType"
              ngx-datatable-cell-template
            >
              <ion-chip
                [outline]="true"
                class="rounded-none"
                [color]="transactionType == 'sell' ? 'danger' : 'primary'"
              >
                <ion-text>
                  {{ transactionType | uppercase }}
                </ion-text>
              </ion-chip>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [width]="90" [sortable]="false" [resizeable]="false">
            <ng-template ngx-datatable-header-template>SHARES</ng-template>
            <ng-template let-row="row" let-quantity="row.quantity" ngx-datatable-cell-template>
              {{ quantity }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [width]="108" [sortable]="false" [resizeable]="false">
            <ng-template ngx-datatable-header-template>FILL PRICE</ng-template>
            <ng-template let-row="row" let-price="row.price" ngx-datatable-cell-template>
              {{ price | currency }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [width]="80"
            class="custom-button"
            [sortable]="false"
            [resizeable]="false"
          >
            <ng-template ngx-datatable-header-template> ACTION </ng-template>
            <ng-template
              let-row="row"
              let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
              ngx-datatable-cell-template
            >
              <div class="bold-row-custom-style">
                <div class="datatable-body-cell-label" style="margin-left: 0px">
                  <div
                    class="add-note-stick"
                    (click)="showTickerAddDetails(row.transactionType, 'edit', row)"
                  >
                    <svg
                      [ngClass]="{
                        'dot-close': row?.notes?.length > 0,
                        'dot-open': row?.notes?.length == 0
                      }"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.33333 0H1.55556C1.143 0 0.747335 0.163888 0.455612 0.455612C0.163888 0.747335 0 1.143 0 1.55556V12.4444C0 12.857 0.163888 13.2527 0.455612 13.5444C0.747335 13.8361 1.143 14 1.55556 14H12.4444C12.857 14 13.2527 13.8361 13.5444 13.5444C13.8361 13.2527 14 12.857 14 12.4444V4.66667L9.33333 0ZM12.4444 12.4444H1.55556V1.55556H8.55556V5.44444H12.4444M10.8889 8.55556H3.11111V7H10.8889M8.55556 10.8889H3.11111V9.33333H8.55556"
                      />
                    </svg>
                  </div>

                  <ion-buttons slot="primary" class="ion-buttons-custom">
                    <ion-button
                      class="p-0 edit-icon"
                      color="medium"
                      fill="clear"
                      (click)="
                        $event.preventDefault();
                        $event.stopPropagation();
                        stockAddUpdate(row.transactionType, 'edit', row)
                      "
                    >
                      <ion-icon slot="icon-only" name="create-outline"></ion-icon>
                    </ion-button>
                    <ion-button
                      class="p-0 delete-icon"
                      color="medium"
                      fill="clear"
                      (click)="$event.preventDefault(); $event.stopPropagation(); delete(row)"
                    >
                      <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
                    </ion-button>
                  </ion-buttons>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-footer></ngx-datatable-footer>
        </ngx-datatable>
      </ion-card-content>
    </ion-card>
    <div class="hide-text" style="margin-top: 18px" (click)="onClickText()">
      <span
        ><svg
          *ngIf="isShowStocks"
          width="22"
          height="22"
          viewBox="0 0 20 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="margin-top: -10px"
        >
          <path
            d="M3.33324 3.33203L16.6666 16.6654M13.7499 13.9619C12.6228 14.5691 11.3488 14.9986 9.99992 14.9986C7.0577 14.9986 4.47187 12.9552 2.989 11.481C2.59758 11.0919 2.40191 10.8974 2.27733 10.5154C2.18849 10.2431 2.18847 9.7542 2.27733 9.48178C2.40194 9.09995 2.59803 8.90495 2.99023 8.51511C3.73763 7.77221 4.76501 6.88502 5.97683 6.18766M16.2499 12.1933C16.5274 11.9491 16.7817 11.7089 17.0097 11.4823L17.0122 11.4797C17.4027 11.0915 17.5985 10.8969 17.7228 10.5159C17.8117 10.2435 17.8115 9.75436 17.7227 9.48203C17.5982 9.10028 17.4022 8.90511 17.0111 8.51628C15.5282 7.0421 12.9421 4.9987 9.99992 4.9987C9.71867 4.9987 9.44067 5.01737 9.16658 5.05243M11.1023 11.2487C10.8085 11.508 10.4226 11.6654 9.99992 11.6654C9.07942 11.6654 8.33324 10.9192 8.33324 9.9987C8.33324 9.54911 8.51125 9.14128 8.80067 8.84145"
            stroke="#8ABF47"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          *ngIf="!isShowStocks"
          width="22"
          height="22"
          viewBox="0 0 20 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.93935 11.0668C4.32716 12.4465 6.74748 14.3589 9.50111 14.3589C12.2547 14.3589 14.6745 12.4465 16.0624 11.0668C16.4284 10.703 16.612 10.5204 16.7285 10.1631C16.8117 9.90825 16.8117 9.45075 16.7285 9.19588C16.612 8.8386 16.4284 8.65602 16.0624 8.29211C14.6745 6.91242 12.2547 5 9.50111 5C6.74748 5 4.32716 6.91242 2.93935 8.29211C2.57306 8.65625 2.38989 8.83844 2.2733 9.19588C2.19015 9.45075 2.19015 9.90825 2.2733 10.1631C2.38989 10.5206 2.57306 10.7027 2.93935 11.0668Z"
            stroke="#72B642"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.94153 9.67702C7.94153 10.5385 8.63987 11.2369 9.50136 11.2369C10.3629 11.2369 11.0612 10.5385 11.0612 9.67702C11.0612 8.81553 10.3629 8.11719 9.50136 8.11719C8.63987 8.11719 7.94153 8.81553 7.94153 9.67702Z"
            stroke="#72B642"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        {{ !isShowStocks ? 'SHOW STOCK LOG' : 'HIDE STOCK LOG' }}</span
      >
    </div>
  </div>
  <div class="page-heading">
    <h2 *ngIf="productSlug == 'h37CPml'">Weekly Calls Sold</h2>
    <h2 *ngIf="productSlug !== 'h37CPml'">Weekly Sold Put</h2>
    <div class="actions">
      <ion-buttons>
        <div class="action">
          <div class="edit">
            <ion-button (click)="positionAddUpdate('weekly', 'add')" color="primary">
              <ion-icon name="add-circle-outline"></ion-icon>
              ADD WEEKLY LEG
            </ion-button>
          </div>
        </div>
      </ion-buttons>
    </div>
  </div>
  <ion-card class="ion-no-margin card-container">
    <ion-card-content class="ion-no-padding">
      <ngx-datatable
        external-analytics
        #externalAnalyticsTable
        class="material body-cell-padding-custom body-cell-stacked ticker-details-table"
        [rows]="showAllWeeklyRows ? weeklyPutPosition : parentData"
        [rowClass]="isRowClass"
        [columnMode]="'force'"
        [rowHeight]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarH]="true"
        [treeFromRelation]="'parentSlug'"
        [treeToRelation]="'slug'"
        (treeAction)="onTreeActionWeekly($event)"
        (activate)="onTreeActionWeekly($event)"
      >
        <ngx-datatable-column
          [width]="40"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.class === 'split-row-custom-style'" class="tooltip tooltip-text-show">
              <svg
                width="10"
                height="10"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="visibility: hidden"
              >
                <path
                  d="M8.492 6.92414C8.46974 6.80615 8.40225 6.70043 8.30283 6.6278C8.20341 6.55517 8.07926 6.5209 7.95482 6.53174C7.83039 6.54257 7.71469 6.59773 7.63051 6.68634C7.54633 6.77496 7.49977 6.89061 7.5 7.01052V11.3312L7.508 11.4176C7.53026 11.5355 7.59775 11.6413 7.69717 11.7139C7.79659 11.7865 7.92074 11.8208 8.04518 11.81C8.16961 11.7991 8.28531 11.744 8.36949 11.6554C8.45367 11.5667 8.50023 11.4511 8.5 11.3312V7.01052L8.492 6.92414ZM8.799 4.85115C8.799 4.66025 8.71998 4.47717 8.57933 4.34218C8.43868 4.20719 8.24791 4.13136 8.049 4.13136C7.85009 4.13136 7.65932 4.20719 7.51867 4.34218C7.37802 4.47717 7.299 4.66025 7.299 4.85115C7.299 5.04205 7.37802 5.22513 7.51867 5.36012C7.65932 5.4951 7.85009 5.57094 8.049 5.57094C8.24791 5.57094 8.43868 5.4951 8.57933 5.36012C8.71998 5.22513 8.799 5.04205 8.799 4.85115ZM16 7.97024C16 5.93397 15.1571 3.9811 13.6569 2.54124C12.1566 1.10138 10.1217 0.29248 8 0.29248C5.87827 0.29248 3.84344 1.10138 2.34315 2.54124C0.842855 3.9811 0 5.93397 0 7.97024C0 10.0065 0.842855 11.9594 2.34315 13.3992C3.84344 14.8391 5.87827 15.648 8 15.648C10.1217 15.648 12.1566 14.8391 13.6569 13.3992C15.1571 11.9594 16 10.0065 16 7.97024ZM1 7.97024C1 7.08801 1.18106 6.21443 1.53284 5.39936C1.88463 4.58429 2.40024 3.8437 3.05025 3.21987C3.70026 2.59604 4.47194 2.10119 5.32122 1.76358C6.1705 1.42597 7.08075 1.2522 8 1.2522C8.91925 1.2522 9.8295 1.42597 10.6788 1.76358C11.5281 2.10119 12.2997 2.59604 12.9497 3.21987C13.5998 3.8437 14.1154 4.58429 14.4672 5.39936C14.8189 6.21443 15 7.08801 15 7.97024C15 9.75197 14.2625 11.4607 12.9497 12.7206C11.637 13.9805 9.85652 14.6883 8 14.6883C6.14348 14.6883 4.36301 13.9805 3.05025 12.7206C1.7375 11.4607 1 9.75197 1 7.97024Z"
                  fill="#000032"
                  fill-opacity="0.39"
                />
              </svg>
              <p class="tooltiptext">
                A Split Strike involves using two different strike prices to create a synthetic
                position, allowing flexibility when your preferred strike isn’t available.
              </p>
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="104"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> DATE SOLD </ng-template>
          <ng-template let-row="row" let-dateSold="row.dateSold" ngx-datatable-cell-template>
            {{ dateSold | date : 'MM/dd/yyyy' : 'UTC' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="104"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>EXPIRATION DATE</ng-template>
          <ng-template
            let-row="row"
            let-expirationPeriod="row.expirationPeriod"
            ngx-datatable-cell-template
          >
            {{ expirationPeriod | date : 'MM/dd/yyyy' : 'UTC' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="90"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> STRIKE SOLD </ng-template>
          <ng-template let-row="row" let-strikeSold="row.strikeSold" ngx-datatable-cell-template>
            {{ strikeSold | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="108"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> UNDERLYING PRICE </ng-template>
          <ng-template
            let-row="row"
            let-underlyingStock="row.underlyingStock"
            ngx-datatable-cell-template
          >
            {{ underlyingStock | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="105"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> #OF CONTRACTS </ng-template>
          <ng-template
            let-row="row"
            let-numberOfContracts="row.numberOfContracts"
            ngx-datatable-cell-template
          >
            {{ numberOfContracts }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="90"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>CREDIT</ng-template>
          <ng-template let-row="row" let-premiumSold="row.premiumSold" ngx-datatable-cell-template>
            {{ premiumSold | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="90"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> EXIT PREMIUM </ng-template>
          <ng-template let-row="row" let-exitPremium="row.exitPremium" ngx-datatable-cell-template>
            <span
              *ngIf="!isIntervalLoader || row.positionStatus === 'closed'"
              [style.color]="getColorBasedOnStatus(row.positionStatus)"
            >
              {{ exitPremium | currency }}</span
            >
            <span *ngIf="checkForLoader(row) && isIntervalLoader">
              <ion-spinner name="dots"></ion-spinner>
            </span>
          </ng-template>
        </ngx-datatable-column>
        <!-- <ngx-datatable-column [sortable]="false"> -->
        <!-- <ng-template ngx-datatable-header-template> PREMIUM EARNED </ng-template> -->
        <!-- <ng-template -->
        <!-- let-row="row" -->
        <!-- let-premiumEarned="row.premiumEarned" -->
        <!-- ngx-datatable-cell-template -->
        <!-- > -->
        <!-- <p>{{ premiumEarned }}</p> -->
        <!-- </ng-template> -->
        <!-- </ngx-datatable-column> -->
        <ngx-datatable-column
          [width]="100"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> P&L </ng-template>
          <ng-template
            let-row="row"
            let-tradeProfitLoss="row.tradeProfitLoss"
            ngx-datatable-cell-template
          >
            <p *ngIf="row.positionStatus !== 'overdue'">{{ tradeProfitLoss | currency }}</p>
            <p *ngIf="row.positionStatus === 'overdue'">N/A</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="120"
          prop="cumulative"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> CUMULATIVE </ng-template>
          <ng-template
            let-row="row"
            let-cumulativeProfitLoss="row.cumulativeProfitLoss"
            ngx-datatable-cell-template
          >
            <p *ngIf="row.class == 'child-row-custom-style'">--</p>
            <p *ngIf="row.class != 'child-row-custom-style'">
              {{ cumulativeProfitLoss > 0 ? '+' : '' }}{{ cumulativeProfitLoss | currency }}
            </p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="65"
          [cellClass]="getHeaderClassProfitLoss"
          prop="cumulative"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> % </ng-template>
          <ng-template
            let-row="row"
            let-profitLossPercentage="row.profitLossPercentage"
            ngx-datatable-cell-template
            let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
          >
            <span *ngIf="!isTotalCumulativeProfitPrice"> {{ profitLossPercentage }}%</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="110"
          [sortable]="true"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> STATUS </ng-template>
          <ng-template
            let-row="row"
            let-positionStatus="row.positionStatus"
            let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
            ngx-datatable-cell-template
          >
            <ion-chip
              *ngIf="!isTotalCumulativeProfitPrice && positionStatus !== 'overdue'"
              [outline]="true"
              [color]="positionStatus == 'closed' ? 'danger' : 'primary'"
            >
              <span
                class="status-dot"
                [ngClass]="{
                  'dot-position-close': positionStatus == 'closed',
                  'dot-position-open': positionStatus == 'open'
                }"
              ></span>
              <ion-text>
                {{ positionStatus | uppercase }}
              </ion-text>
            </ion-chip>
            <ion-chip
              *ngIf="!isTotalCumulativeProfitPrice && positionStatus === 'overdue'"
              [outline]="true"
              [color]="'warning'"
              (click)="onOverdueClick('weekly', row); $event.stopPropagation()"
            >
              <!-- <span class="status-dot"></span> -->
              <svg
                class="status-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2425 1.7573C9.15867 0.672563 7.65487 -0.000328005 6 1.19949e-07C4.34515 -0.000328005 2.84114 0.672563 1.75727 1.7573C0.672563 2.84114 -0.000351425 4.34513 1.37688e-07 6C-0.000351425 7.65487 0.672563 9.15886 1.75727 10.2427C2.84114 11.3274 4.34515 12.0004 6 12C7.65487 12.0004 9.15867 11.3274 10.2425 10.2427C11.3274 9.15886 12.0003 7.65487 12 6C12.0003 4.34513 11.3274 2.84114 10.2425 1.7573ZM9.36267 2.63733C10.2243 3.4998 10.7552 4.68471 10.7556 6C10.7552 7.31527 10.2243 8.50017 9.36267 9.36267C8.50001 10.2243 7.3151 10.7552 6 10.7556C4.68471 10.7552 3.49983 10.2243 2.63733 9.36267C1.77567 8.50017 1.24479 7.31527 1.24444 6C1.24477 4.68473 1.77567 3.49983 2.6373 2.63733C3.4998 1.7757 4.68471 1.24479 5.99998 1.24444C7.3151 1.24481 8.50001 1.7757 9.36267 2.63733Z"
                  fill="#FF9800"
                />
                <path
                  d="M5.78206 7.11563C5.78937 7.16427 5.81368 7.21095 5.85481 7.25593C5.89578 7.30072 5.94439 7.3231 6.00048 7.3231C6.13102 7.3231 6.20394 7.25401 6.2187 7.11563L6.65567 3.75905C6.66296 3.69969 6.66678 3.61548 6.66678 3.50715C6.66678 3.3391 6.60898 3.18631 6.49334 3.04794C6.37756 2.90991 6.21331 2.84082 6.00048 2.84082C5.77981 2.84082 5.61385 2.91165 5.50205 3.0533C5.39007 3.19168 5.33398 3.3429 5.33398 3.50715C5.33398 3.60816 5.33764 3.69202 5.34509 3.75905L5.78206 7.11563Z"
                  fill="#FF9800"
                />
                <path
                  d="M5.99989 9.15922C6.19016 9.15922 6.35056 9.09411 6.48148 8.96323C6.61203 8.83285 6.67733 8.67209 6.67733 8.4818C6.67733 8.29135 6.61205 8.13076 6.48148 8.00002C6.35459 7.87295 6.194 7.80957 5.99989 7.80957C5.80963 7.80957 5.64901 7.8752 5.51811 8.0056C5.38738 8.1365 5.32227 8.29501 5.32227 8.4818C5.32227 8.6608 5.38738 8.81947 5.51811 8.95766C5.64901 9.09223 5.80963 9.15922 5.99989 9.15922Z"
                  fill="#FF9800"
                />
              </svg>

              <ion-text>
                {{ positionStatus | uppercase }}
              </ion-text>
            </ion-chip>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="80"
          class="custom-button"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> ACTION </ng-template>
          <ng-template
            let-row="row"
            let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
            let-positionStatus="row.positionStatus"
            ngx-datatable-cell-template
          >
            <div
              class="add-note-stick"
              (click)="showTickerAddDetails('weekly', 'edit', row)"
              *ngIf="!row.isSubData && !isTotalCumulativeProfitPrice"
            >
              <svg
                [ngClass]="{
                  'dot-close': !!row.noteDes,
                  'dot-open': !row.noteDes
                }"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 0H1.55556C1.143 0 0.747335 0.163888 0.455612 0.455612C0.163888 0.747335 0 1.143 0 1.55556V12.4444C0 12.857 0.163888 13.2527 0.455612 13.5444C0.747335 13.8361 1.143 14 1.55556 14H12.4444C12.857 14 13.2527 13.8361 13.5444 13.5444C13.8361 13.2527 14 12.857 14 12.4444V4.66667L9.33333 0ZM12.4444 12.4444H1.55556V1.55556H8.55556V5.44444H12.4444M10.8889 8.55556H3.11111V7H10.8889M8.55556 10.8889H3.11111V9.33333H8.55556"
                />
              </svg>
            </div>

            <ion-buttons
              *ngIf="!isTotalCumulativeProfitPrice"
              slot="primary"
              class="ion-justify-content-end custom-button ion-buttons-custom"
            >
              <ion-button
                *ngIf="!row.isSubData"
                class="p-0 edit-icon"
                color="medium"
                fill="clear"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  positionAddUpdate('weekly', 'edit', row)
                "
              >
                <ion-icon slot="icon-only" name="create-outline"></ion-icon>
              </ion-button>
              <ion-button
                *ngIf="!row.isSubData"
                class="p-0 delete-icon"
                color="medium"
                fill="clear"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  openConfirmationDialog(row, 'weekly')
                "
              >
                <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer></ngx-datatable-footer>
      </ngx-datatable>
      <div class="show-more-button" *ngIf="weeklyCount > 3">
        <!-- weeklyCount instead of weeklyPutPosition.length > 4 -->
        <button class="show-button" ion-button (click)="toggleWeeklyShowMore()">
          {{
            showAllWeeklyRows
              ? 'SHOW LESS'
              : 'SHOW&nbsp;&nbsp;' + (weeklyCount - 3) + '&nbsp;&nbsp;MORE'
          }}
        </button>
      </div>
    </ion-card-content>
  </ion-card>

  <div class="page-heading ion-padding-top">
    <h2>Long Put Protection</h2>

    <div class="actions">
      <ion-buttons>
        <div class="action">
          <div class="edit">
            <ion-button (click)="positionAddUpdate('long', 'add')" color="primary">
              <ion-icon name="add-circle-outline"></ion-icon>
              ADD PROTECTIVE LEG
            </ion-button>
          </div>
        </div>
      </ion-buttons>
    </div>
  </div>
  <ion-card class="ion-no-margin card-container">
    <ion-card-content class="ion-no-padding">
      <ngx-datatable
        external-analytics
        #externalAnalyticsTable
        class="material body-cell-padding-custom body-cell-stacked ticker-details-table"
        [rows]="showAllLongRows ? longPutPosition : longParentData"
        [rowClass]="isRowClass"
        [columnMode]="'force'"
        [rowHeight]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarH]="true"
        [treeFromRelation]="'parentSlug'"
        [treeToRelation]="'slug'"
        (treeAction)="onTreeActionLong($event)"
        (activate)="onTreeActionLong($event)"
      >
        <ngx-datatable-column
          [width]="40"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.class === 'split-row-custom-style'" class="tooltip tooltip-text-show">
              <svg
                width="10"
                height="10"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="visibility: hidden"
              >
                <path
                  d="M8.492 6.92414C8.46974 6.80615 8.40225 6.70043 8.30283 6.6278C8.20341 6.55517 8.07926 6.5209 7.95482 6.53174C7.83039 6.54257 7.71469 6.59773 7.63051 6.68634C7.54633 6.77496 7.49977 6.89061 7.5 7.01052V11.3312L7.508 11.4176C7.53026 11.5355 7.59775 11.6413 7.69717 11.7139C7.79659 11.7865 7.92074 11.8208 8.04518 11.81C8.16961 11.7991 8.28531 11.744 8.36949 11.6554C8.45367 11.5667 8.50023 11.4511 8.5 11.3312V7.01052L8.492 6.92414ZM8.799 4.85115C8.799 4.66025 8.71998 4.47717 8.57933 4.34218C8.43868 4.20719 8.24791 4.13136 8.049 4.13136C7.85009 4.13136 7.65932 4.20719 7.51867 4.34218C7.37802 4.47717 7.299 4.66025 7.299 4.85115C7.299 5.04205 7.37802 5.22513 7.51867 5.36012C7.65932 5.4951 7.85009 5.57094 8.049 5.57094C8.24791 5.57094 8.43868 5.4951 8.57933 5.36012C8.71998 5.22513 8.799 5.04205 8.799 4.85115ZM16 7.97024C16 5.93397 15.1571 3.9811 13.6569 2.54124C12.1566 1.10138 10.1217 0.29248 8 0.29248C5.87827 0.29248 3.84344 1.10138 2.34315 2.54124C0.842855 3.9811 0 5.93397 0 7.97024C0 10.0065 0.842855 11.9594 2.34315 13.3992C3.84344 14.8391 5.87827 15.648 8 15.648C10.1217 15.648 12.1566 14.8391 13.6569 13.3992C15.1571 11.9594 16 10.0065 16 7.97024ZM1 7.97024C1 7.08801 1.18106 6.21443 1.53284 5.39936C1.88463 4.58429 2.40024 3.8437 3.05025 3.21987C3.70026 2.59604 4.47194 2.10119 5.32122 1.76358C6.1705 1.42597 7.08075 1.2522 8 1.2522C8.91925 1.2522 9.8295 1.42597 10.6788 1.76358C11.5281 2.10119 12.2997 2.59604 12.9497 3.21987C13.5998 3.8437 14.1154 4.58429 14.4672 5.39936C14.8189 6.21443 15 7.08801 15 7.97024C15 9.75197 14.2625 11.4607 12.9497 12.7206C11.637 13.9805 9.85652 14.6883 8 14.6883C6.14348 14.6883 4.36301 13.9805 3.05025 12.7206C1.7375 11.4607 1 9.75197 1 7.97024Z"
                  fill="#000032"
                  fill-opacity="0.39"
                />
              </svg>
              <p class="tooltiptext">
                A Split Strike involves using two different strike prices to create a synthetic
                position, allowing flexibility when your preferred strike isn’t available.
              </p>
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="104"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>DATE PURCHASED</ng-template>
          <ng-template
            let-row="row"
            let-datePurchased="row.datePurchased"
            ngx-datatable-cell-template
          >
            {{ datePurchased | date : 'MM/dd/yyyy' : 'UTC' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="104"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>EXPIRATION DATE</ng-template>
          <ng-template
            let-row="row"
            let-expirationPeriod="row.expirationPeriod"
            ngx-datatable-cell-template
          >
            {{ expirationPeriod | date : 'MM/dd/yyyy' : 'UTC' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="90"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>STRIKE BOUGHT</ng-template>
          <ng-template
            let-row="row"
            let-strikeBought="row.strikeBought"
            ngx-datatable-cell-template
          >
            {{ strikeBought | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="108"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>UNDERLYING PRICE</ng-template>
          <ng-template
            let-row="row"
            let-underlyingStock="row.underlyingStock"
            ngx-datatable-cell-template
          >
            {{ underlyingStock | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="105"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>#OF CONTRACTS</ng-template>
          <ng-template
            let-row="row"
            let-numberOfContracts="row.numberOfContracts"
            ngx-datatable-cell-template
          >
            {{ numberOfContracts }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="90"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>PREMIUM PAID</ng-template>
          <ng-template let-row="row" let-premiumPaid="row.premiumPaid" ngx-datatable-cell-template>
            {{ premiumPaid | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="90"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>EXIT PREMIUM</ng-template>
          <ng-template let-row="row" let-exitPremium="row.exitPremium" ngx-datatable-cell-template>
            <span
              *ngIf="!isIntervalLoader || row.positionStatus === 'closed'"
              [style.color]="getColorBasedOnStatus(row.positionStatus)"
            >
              {{ exitPremium | currency }}</span
            >
            <span *ngIf="checkForLoader(row) && isIntervalLoader">
              <ion-spinner name="dots"></ion-spinner>
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="100"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>P&L</ng-template>
          <ng-template
            let-row="row"
            let-tradeProfitLoss="row.tradeProfitLoss"
            ngx-datatable-cell-template
          >
            <!-- {{ tradeProfitLoss | currency }} -->
            <p *ngIf="row.positionStatus !== 'overdue'">{{ tradeProfitLoss | currency }}</p>
            <p *ngIf="row.positionStatus === 'overdue'">N/A</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="120"
          prop="cumulative"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> CUMULATIVE </ng-template>
          <ng-template
            let-row="row"
            let-cumulativeProfitLoss="row.cumulativeProfitLoss"
            ngx-datatable-cell-template
          >
            <p *ngIf="row.class == 'child-row-custom-style'">--</p>
            <p *ngIf="row.class != 'child-row-custom-style'">
              {{ cumulativeProfitLoss > 0 ? '+' : '' }}{{ cumulativeProfitLoss | currency }}
            </p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="65"
          [cellClass]="getHeaderClassProfitLoss"
          prop="cumulative"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> % </ng-template>
          <ng-template
            let-row="row"
            let-profitLossPercentage="row.profitLossPercentage"
            ngx-datatable-cell-template
            let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
          >
            <span *ngIf="!isTotalCumulativeProfitPrice"> {{ profitLossPercentage }}%</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="110"
          [sortable]="true"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> STATUS </ng-template>
          <ng-template
            let-row="row"
            let-positionStatus="row.positionStatus"
            let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
            ngx-datatable-cell-template
          >
            <ion-chip
              *ngIf="!isTotalCumulativeProfitPrice && positionStatus !== 'overdue'"
              [outline]="true"
              [color]="positionStatus == 'closed' ? 'danger' : 'primary'"
            >
              <span
                class="status-dot"
                [ngClass]="{
                  'dot-position-close': positionStatus == 'closed',
                  'dot-position-open': positionStatus == 'open'
                }"
              ></span>
              <ion-text>
                {{ positionStatus | uppercase }}
              </ion-text>
            </ion-chip>
            <ion-chip
              *ngIf="!isTotalCumulativeProfitPrice && positionStatus === 'overdue'"
              [outline]="true"
              [color]="'warning'"
              (click)="onOverdueClick('long', row); $event.stopPropagation()"
            >
              <!-- <span class="status-dot"></span> -->
              <svg
                class="status-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2425 1.7573C9.15867 0.672563 7.65487 -0.000328005 6 1.19949e-07C4.34515 -0.000328005 2.84114 0.672563 1.75727 1.7573C0.672563 2.84114 -0.000351425 4.34513 1.37688e-07 6C-0.000351425 7.65487 0.672563 9.15886 1.75727 10.2427C2.84114 11.3274 4.34515 12.0004 6 12C7.65487 12.0004 9.15867 11.3274 10.2425 10.2427C11.3274 9.15886 12.0003 7.65487 12 6C12.0003 4.34513 11.3274 2.84114 10.2425 1.7573ZM9.36267 2.63733C10.2243 3.4998 10.7552 4.68471 10.7556 6C10.7552 7.31527 10.2243 8.50017 9.36267 9.36267C8.50001 10.2243 7.3151 10.7552 6 10.7556C4.68471 10.7552 3.49983 10.2243 2.63733 9.36267C1.77567 8.50017 1.24479 7.31527 1.24444 6C1.24477 4.68473 1.77567 3.49983 2.6373 2.63733C3.4998 1.7757 4.68471 1.24479 5.99998 1.24444C7.3151 1.24481 8.50001 1.7757 9.36267 2.63733Z"
                  fill="#FF9800"
                />
                <path
                  d="M5.78206 7.11563C5.78937 7.16427 5.81368 7.21095 5.85481 7.25593C5.89578 7.30072 5.94439 7.3231 6.00048 7.3231C6.13102 7.3231 6.20394 7.25401 6.2187 7.11563L6.65567 3.75905C6.66296 3.69969 6.66678 3.61548 6.66678 3.50715C6.66678 3.3391 6.60898 3.18631 6.49334 3.04794C6.37756 2.90991 6.21331 2.84082 6.00048 2.84082C5.77981 2.84082 5.61385 2.91165 5.50205 3.0533C5.39007 3.19168 5.33398 3.3429 5.33398 3.50715C5.33398 3.60816 5.33764 3.69202 5.34509 3.75905L5.78206 7.11563Z"
                  fill="#FF9800"
                />
                <path
                  d="M5.99989 9.15922C6.19016 9.15922 6.35056 9.09411 6.48148 8.96323C6.61203 8.83285 6.67733 8.67209 6.67733 8.4818C6.67733 8.29135 6.61205 8.13076 6.48148 8.00002C6.35459 7.87295 6.194 7.80957 5.99989 7.80957C5.80963 7.80957 5.64901 7.8752 5.51811 8.0056C5.38738 8.1365 5.32227 8.29501 5.32227 8.4818C5.32227 8.6608 5.38738 8.81947 5.51811 8.95766C5.64901 9.09223 5.80963 9.15922 5.99989 9.15922Z"
                  fill="#FF9800"
                />
              </svg>
              <ion-text>
                {{ positionStatus | uppercase }}
              </ion-text>
            </ion-chip>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="80"
          class="custom-button"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> ACTION </ng-template>
          <ng-template
            let-row="row"
            let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
            ngx-datatable-cell-template
          >
            <div
              class="add-note-stick"
              (click)="showTickerAddDetails('long', 'edit', row)"
              *ngIf="!row.isSubData && !isTotalCumulativeProfitPrice"
            >
              <svg
                [ngClass]="{
                  'dot-close': !!row.noteDes,
                  'dot-open': !row.noteDes
                }"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 0H1.55556C1.143 0 0.747335 0.163888 0.455612 0.455612C0.163888 0.747335 0 1.143 0 1.55556V12.4444C0 12.857 0.163888 13.2527 0.455612 13.5444C0.747335 13.8361 1.143 14 1.55556 14H12.4444C12.857 14 13.2527 13.8361 13.5444 13.5444C13.8361 13.2527 14 12.857 14 12.4444V4.66667L9.33333 0ZM12.4444 12.4444H1.55556V1.55556H8.55556V5.44444H12.4444M10.8889 8.55556H3.11111V7H10.8889M8.55556 10.8889H3.11111V9.33333H8.55556"
                />
              </svg>
            </div>

            <ion-buttons
              *ngIf="!isTotalCumulativeProfitPrice"
              slot="primary"
              class="ion-buttons-custom"
            >
              <ion-button
                *ngIf="!row.isSubData"
                class="p-0 edit-icon"
                color="medium"
                fill="clear"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  positionAddUpdate('long', 'edit', row)
                "
              >
                <ion-icon slot="icon-only" name="create-outline"></ion-icon>
              </ion-button>
              <ion-button
                *ngIf="!row.isSubData"
                class="p-0 delete-icon"
                color="medium"
                fill="clear"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  openConfirmationDialog(row, 'long')
                "
              >
                <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer></ngx-datatable-footer>
      </ngx-datatable>
      <div class="show-more-button" *ngIf="longCount > 3">
        <button class="show-button" ion-button (click)="toggleLongShowMore()">
          {{
            showAllLongRows
              ? 'SHOW LESS'
              : 'SHOW&nbsp;&nbsp;' + (longCount - 3) + '&nbsp;&nbsp;MORE'
          }}
        </button>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>
