<div>
  <div class="body-modal">
    <div class="modal-heading">{{ productSlug == 'h37CPml' ? 'Select Trade' : 'Add Trade' }}</div>
    <div class="modal-subheading" *ngIf="productSlug == 'ib7W39v'">
      Type the name of the company or ticker symbol to track the a Money Press Trade
    </div>
    <div class="form-container">
      <form [formGroup]="addTickerTradeForm">
        <!-- <div class="input-container">
          <ion-label>Ticker</ion-label>
          <ion-item class="ion-item-no-inner-padding">
            <ion-select
              lines="none"
              placeholder="Select The Ticker"
              interface="popover"
              [interfaceOptions]="customPopoverOptions"
              formControlName="ticker"
              (ionScroll)="loadMoreData($event)"
            >
              <ion-select-option *ngFor="let item of visibleTickerList" [value]="item.stock_id">{{
                item.name
              }}</ion-select-option>
              <ion-select-option *ngIf="showLoadMoreOption" value="loadMoreOption">
                Load More
              </ion-select-option>
            </ion-select>
          </ion-item>
          <div
            *ngIf="
              addTickerTradeForm.get('ticker').invalid && addTickerTradeForm.get('ticker').touched
            "
          >
            <div *ngIf="addTickerTradeForm.get('ticker').errors.required">Ticker is required.</div>
          </div>
        </div> -->
        <div class="input-container">
          <!-- <ion-label>Ticker</ion-label> -->
          <div class="loading-spinner" *ngIf="isLoading"></div>
          <ion-searchbar
            search-icon="search-outline"
            debounce="1000"
            id="search"
            class="ion-no-padding"
            placeholder="Type name or ticker symbol"
            (ionChange)="onSearchLookupNew($event)"
            [value]="
              selectedTicker.symbol && selectedTicker.name
                ? selectedTicker.symbol + ' - ' + selectedTicker.name
                : ''
            "
          >
          </ion-searchbar>
          <div
            class="taglist-search"
            *ngIf="tickerListVisibility && searchResult && searchResult.length > 0"
          >
            <!-- <span class="close" (click)="hideTickerList()">&#10005;</span> -->
            <p *ngFor="let item of searchResult" (click)="addTicker(item.symbol, item.name, item)">
              {{ item.symbol }} - {{ item.name }}
            </p>
          </div>
        </div>
        <div class="footer-modal">
          <ion-button class="close-btn" fill="outline" (click)="close()">Close</ion-button>
          <ion-button
            class="add-btn"
            (click)="onAddNewTrade()"
            fill="solid"
            [disabled]="addTickerTradeForm.invalid"
            >Add</ion-button
          >
        </div>
      </form>
    </div>
  </div>
</div>
