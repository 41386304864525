import { TradeTrackersInsidersComponent } from './trade-trackers-insiders/trade-trackers-insiders.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TradeTrackersRoutingModule } from './trade-trackers-routing.module';
import { TradeTrackersProductsComponent } from './products/products.component';
import { ProductTickersComponent } from './product-tickers/product-tickers.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TickerDetailsComponent } from './ticker-details/ticker-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppDatePickerModule } from 'src/app/components/date-picker/date-picker.module';
import { TickerTradeModelComponent } from './ticker-trade-model/ticker-trade-model.component';
import { AddPotionModalComponent } from './add-potion-modal/add-potion-modal.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { TradeHistoryComponent } from './trade-history/trade-history.component';
import { TradeCloseModelComponent } from './trade-close-model/trade-close-model.component';
import { TickerDetailsNoteModelComponent } from './ticker-details-note-model/ticker-details-note-model.component';
import { TickerDeleteModelComponent } from './ticker-delete-model/ticker-delete-model.component';
import { TradeLegExpirationComponent } from './trade-leg-expiration/trade-leg-expiration.component';
import { AddStockPurchaseComponent } from './add-stock-purchase/add-stock-purchase.component';
import { MitPositionPlComponent } from './mit-position-pl/mit-position-pl.component';
import { TradeTrackersAlertComponent } from './trade-trackers-alert/trade-trackers-alert.component';

@NgModule({
  declarations: [
    TradeTrackersProductsComponent,
    ProductTickersComponent,
    TickerDetailsComponent,
    TickerTradeModelComponent,
    TradeTrackersInsidersComponent,
    AddPotionModalComponent,
    TradeCloseModelComponent,
    TickerDeleteModelComponent,
    TradeHistoryComponent,
    TickerDetailsNoteModelComponent,
    TradeLegExpirationComponent,
    AddStockPurchaseComponent,
    MitPositionPlComponent,
    TradeTrackersAlertComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    TradeTrackersRoutingModule,
    IonicModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    AppDatePickerModule,
  ],
  exports: [
    AddPotionModalComponent,
    TradeHistoryComponent,
    AddStockPurchaseComponent,
    TradeTrackersAlertComponent,
  ],
  entryComponents: [
    AddPotionModalComponent,
    AddStockPurchaseComponent,
    TradeTrackersAlertComponent,
  ],
})
export class TradeTrackerModule {}
