import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-mit-position-pl',
  templateUrl: './mit-position-pl.component.html',
  styleUrls: ['./mit-position-pl.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MitPositionPlComponent implements OnInit {
  @Input() data;
  @Input() weeklyPnL;
  @Input() longPnL;
  @Input() PnL;
  @Input() type;
  @Input() stockGainLoss;
  constructor(private modalController: ModalController) {}

  ngOnInit() {}
  cancel() {
    this.modalController.dismiss();
  }
}
