<ion-header class="ion-no-border">
  <ion-toolbar style="border-bottom: 1px solid #d1d1d1">
    <ion-title class="modal-title">{{
      type == 'stock' ? 'Stock Gain/Loss' : 'Position P&L'
    }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="type == 'stock'" class="position-p-l">
  <ion-list lines="full">
    <ion-item>
      <ion-label class="label-text">Stock Realized Gain/Loss:</ion-label>
      <ion-text class="value-text">{{ data?.mit?.realizedGainAndLoss | currency }}</ion-text>
    </ion-item>

    <ion-item>
      <ion-label class="label-text">Stock Unrealized Gain/Loss:</ion-label>
      <ion-text class="value-text negative">{{ data?.mit?.profitOrLoss || 0 | currency }}</ion-text>
    </ion-item>
    <ion-item class="pnl-total">
      <ion-label class="label-text">Stock Gain/Loss:</ion-label>
      <ion-text class="value-text total">{{ stockGainLoss | currency }}</ion-text>
    </ion-item>
  </ion-list>
</ion-content>
<ion-content *ngIf="type !== 'stock'" class="position-p-l">
  <ion-list lines="full">
    <ion-item>
      <ion-label class="label-text">MIT Stock Realized Gain/Loss:</ion-label>
      <ion-text class="value-text">{{ data?.mit?.realizedGainAndLoss | currency }}</ion-text>
    </ion-item>

    <ion-item>
      <ion-label class="label-text">MIT Stock Unrealized Gain/Loss:</ion-label>
      <ion-text class="value-text negative">{{ data?.mit?.profitOrLoss || 0 | currency }}</ion-text>
    </ion-item>

    <ion-item>
      <ion-label class="label-text">Weekly Calls Sold:</ion-label>
      <ion-text class="value-text">{{ weeklyPnL | currency }}</ion-text>
    </ion-item>

    <ion-item>
      <ion-label class="label-text">Long Put Protection:</ion-label>
      <ion-text class="value-text">{{ longPnL | currency }}</ion-text>
    </ion-item>

    <ion-item class="pnl-total">
      <ion-label class="label-text">Position P&L:</ion-label>
      <ion-text class="value-text total">{{ PnL | currency }}</ion-text>
    </ion-item>
  </ion-list>
</ion-content>
