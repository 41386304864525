<div class="drawer-header">
  <div class="header-container">
    <div class="back-to">
      <a (click)="cancel()"><ion-icon name="chevron-back-outline"></ion-icon>Back to Alert </a>
      <ion-buttons slot="end">
        <ion-button (click)="cancel()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </div>
</div>
<ng-container>
  <div class="page-heading">
    <h2>Set Alert For {{ data.symbol }}</h2>
  </div>
  <div class="form-container">
    <div class="form-container">
      <form (ngSubmit)="onAlertCreateSubmit()" [formGroup]="tickerAlertForm">
        <div class="input-container">
          <ion-label>Set Alert</ion-label>
          <div class="alert">Choose the trigger type for this alert</div>
          <ion-item lines="none" class="ion-item-no-inner-padding">
            <ion-select
              lines="none"
              placeholder="Select the option"
              interface="popover"
              [interfaceOptions]="customPopoverOptions"
              formControlName="alertType"
              (ionChange)="selectedOption()"
              [(ngModel)]="alertType"
            >
              <ng-container *ngFor="let type of alertTypes">
                <ion-select-option [value]="type.value">{{ type.label }}</ion-select-option>
              </ng-container>
            </ion-select>
          </ion-item>
        </div>
        <div [ngSwitch]="alertType">
          <div class="input-container" *ngSwitchCase="'on_date'">
            <ion-label>Date & Time</ion-label>
            <ion-item lines="none" class="ion-item-no-inner-padding">
              <app-alert-date-picker
                [min]="currentDate"
                [feedDate]="tickerAlertForm.get('triggerDate').value"
                [hash]="'datetimetickeralertdatetime'"
                [label]="'on_date'"
                [options]="datePickerOptions"
                (onDateChange)="onAlertDateChange($event)"
              />
            </ion-item>
          </div>

          <div class="input-container stock-price-wrap" *ngSwitchCase="'stock_price'">
            <div class="currentStocklabel">
              <ion-label>Current Stock Price: </ion-label>
              <ion-label class="label-color">${{ data?.currentStock }}</ion-label>
            </div>
            <div class="input-container" *ngSwitchCase="'stock_price'">
              <ion-label>Select Condition</ion-label>
              <ion-item lines="none" class="ion-item-no-inner-padding">
                <ion-select
                  lines="none"
                  placeholder="Select the Condition"
                  interface="popover"
                  [interfaceOptions]="customPopoverOptions"
                  formControlName="conditionType"
                >
                  <ng-container *ngFor="let type of conditionTypes">
                    <ion-select-option [value]="type.value">{{ type.label }}</ion-select-option>
                  </ng-container>
                </ion-select>
              </ion-item>
            </div>
            <div class="set-alert-price">
              <ion-label>Set Alert Trigger Price</ion-label>
              <ion-item lines="none" class="ion-item-no-inner-padding">
                $
                <ion-input formControlName="triggerPrice" type="number"></ion-input>
              </ion-item>
            </div>
          </div>
          <!-- <div class="input-container" *ngSwitchCase="'stock_price'"> -->
          <!-- <ion-label>Expiration Date</ion-label> -->
          <!-- <ion-item lines="none" class="ion-item-no-inner-padding"> -->
          <!-- <ion-input -->
          <!-- formControlName="expirationDate" -->
          <!-- readonly="true" -->
          <!-- (click)="openDatePicker()" -->
          <!-- (focus)="openDatePicker()" -->
          <!-- placeholder="MM/DD/YYYY" -->
          <!-- ></ion-input> -->
          <!-- <ion-datetime -->
          <!-- presentation="date" -->
          <!-- (ionChange)="closeDatePicker($event)" -->
          <!-- [hidden]="!showDatePicker" -->
          <!-- displayFormat="MM/DD/YYYY" -->
          <!-- pickerFormat="MM DD YYYY" -->
          <!-- ></ion-datetime> -->
          <!-- </ion-item> -->
          <!-- <div *ngIf="tickerAlertForm.get('expirationDate').errors?.invalidDate" class="error"> -->
          <!-- Please select the valid date. -->
          <!-- </div> -->
          <!-- </div> -->

          <div class="input-container" *ngSwitchCase="'stock_price'">
            <div class="checkbox-add">
              <ion-checkbox
                color="primary"
                slot="start"
                (ionChange)="onSelect($event)"
                formControlName="isExpire"
              ></ion-checkbox>
              <ion-label>Alert Expires?</ion-label>
            </div>
            <div *ngIf="isExpire">
              <ion-label>Alert Expiration Date</ion-label>
              <ion-item lines="none" class="ion-item-no-inner-padding">
                <app-alert-date-picker
                  [min]="currentDate"
                  [feedDate]="tickerAlertForm.get('expirationDate').value || StockCurrentDate"
                  [hash]="'datetimetickeralertdatetime'"
                  [label]="''"
                  [options]="datePickerOptions"
                  (onDateChange)="onAlertStockDateChange($event)"
                />
              </ion-item>
            </div>
          </div>
        </div>
        <div class="input-container" *ngIf="alertType">
          <label style="font-size: 18px" for="notes">Message</label>
          <textarea
            class="message-description"
            formControlName="message"
            id="message"
            rows="4"
            cols="50"
          ></textarea>
        </div>
        <ion-button
          type="submit"
          [disabled]="tickerAlertForm.invalid"
          color="primary"
          fill="solid"
          style="width: 100%"
        >
          {{ data?.mode === 'edit' ? 'Update' : 'Create' }} Alert
        </ion-button>
      </form>
    </div>
  </div>
</ng-container>
