import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuController } from '@ionic/angular';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';
import moment from 'moment';

@Component({
  selector: 'app-add-stock-purchase',
  templateUrl: './add-stock-purchase.component.html',
  styleUrls: ['./add-stock-purchase.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddStockPurchaseComponent implements OnInit {
  @Input() componentData: any;
  addMITStock: FormGroup;
  formTitle: string;
  formType: string;
  showDatePicker: boolean = false;
  mode: any;
  selectedDateTime: string;
  trackerSlug: any;
  editedData: any;
  allData: any;
  isaddNote: boolean = false;
  isNew: boolean;
  notesSlug: any;
  constructor(
    private menuService: commonRightMenuService,
    public menuController: MenuController,
    private toolsApi: ToolServiceService,
    private toastServ: ToastService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.addMITStock = this.formBuilder.group({
      purchasedDate: ['', Validators.required],
      quantity: ['', Validators.required],
      price: ['', Validators.required],
      noteDes: [''],
      notesCheck: [false],
    });
  }

  ngOnInit() {
    if (this.componentData && this.componentData.componentType === 'stockComponent') {
      this.setInitData();
    }
    this.menuService.getOpenMenuSubject().subscribe((res: any) => {
      this.componentData = {};
      if (res && res.componentType === 'stockComponent') {
        this.componentData = res;
        this.setInitData();
      }
    });
  }
  cancel(isNotify = 'true') {
    this.menuController.close();
    this.addMITStock.reset();
  }
  setInitData() {
    this.formTitle = this.formType === 'sell' ? 'Add Stock Sold' : 'Add Stock Purchase';
    if (this.componentData) {
      this.isaddNote = false;
      this.addMITStock.patchValue({
        noteDes: '',
        notesCheck: this.isaddNote,
      });
      this.formType = this.componentData.type;
      this.mode = this.componentData.mode;
      this.trackerSlug = this.componentData.slug;
      this.editedData = this.componentData?.editData;
      this.allData = this.componentData?.allData;
      if (this.componentData.mode == 'edit') {
        this.formTitle = this.formType === 'sell' ? 'Edit Stock Sold' : 'Edit Stock Purchase';
        this.getNotes(this.editedData?._id);
      }

      this.addMITStock.patchValue({
        purchasedDate: moment.utc(this.editedData?.purchasedDate).format('MM/DD/YYYY'),
        quantity: this.editedData?.quantity,
        price: this.editedData?.price,
      });
      // if (this.editedData?._id) {
      //   this.getNotes(this.editedData?._id);
      // }
    }
  }
  openDatePicker() {
    if (!this.showDatePicker) {
      this.showDatePicker = true;
      const purchasedDateValue = this.addMITStock.get('purchasedDate').value;
      this.selectedDateTime = purchasedDateValue ? purchasedDateValue : new Date().toISOString();
      this.cdr.detectChanges();
    } else {
      this.showDatePicker = false;
    }
  }
  closeDatePicker(event) {
    const value = event.target.value;
    this.addMITStock.patchValue({
      purchasedDate: this.formatDate(new Date(value)),
    });
    this.showDatePicker = false;
  }
  formatDate(date: Date): string {
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero for month
    const day = ('0' + date.getDate()).slice(-2); // Add leading zero for day
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }
  onSubmit() {
    let payload: any;
    let notesPayload: any;
    let shares =
      this.mode === 'edit'
        ? this.editedData?.quantity + this.allData?.shares
        : this.allData?.shares;
    if (this.formType == 'sell' && this.addMITStock.controls.quantity.value > shares) {
      this.toastServ.presentToast(
        "Share's Quantity should not exceed the available shares",
        'danger'
      );
    } else {
      if (this.mode === 'add') {
        payload = {
          quantity: this.addMITStock.controls.quantity.value,
          price: this.addMITStock.controls.price.value,
          transactionType: this.formType,
          tickerSlug: this.trackerSlug,
          purchasedDate: this.addMITStock.controls.purchasedDate.value,
        };
        this.toolsApi.createMITStock(payload).subscribe((res: any) => {
          if (res) {
            if (this.isaddNote) {
              notesPayload = {
                tickerSlug: this.trackerSlug,
                description: this.addMITStock.controls.noteDes.value,
                mitId: payload?._id,
                type: 'mit',
              };
              this.toolsApi.createLegNotes(notesPayload).subscribe((res: any) => {});
            }
            this.menuService.setCloseMenuSubject(payload);
            this.addMITStock.reset();
            this.menuController.close('payload');
            this.cancel('false');
            this.toastServ.presentToast(res?.message, 'success');
          }
        });
      }
      if (this.mode === 'edit') {
        payload = {
          quantity: this.addMITStock.controls.quantity.value,
          price: this.addMITStock.controls.price.value,
          transactionType: this.formType,
          purchasedDate: this.addMITStock.controls.purchasedDate.value,
        };
        this.toolsApi.updateMITStock(this.editedData?._id, payload).subscribe((res: any) => {
          if (res) {
            notesPayload = {
              tickerSlug: this.trackerSlug,
              description: this.addMITStock.controls.noteDes.value,
              mitId: this.editedData?._id,
              type: 'mit',
            };
            if (this.isNew) {
              if (!!this.addMITStock.controls.noteDes.value) {
                this.toolsApi.createLegNotes(notesPayload).subscribe((res: any) => {});
              }
            } else {
              if (this.addMITStock.controls.noteDes.value.trim() == '') {
                this.toolsApi.deleteNote(this.notesSlug).subscribe((response: any) => {});
              } else {
                this.toolsApi
                  .updateLegNotes(this.notesSlug, notesPayload)
                  .subscribe((res: any) => {});
              }
            }
            this.menuService.setCloseMenuSubject(payload);
            this.addMITStock.reset();
            this.menuController.close('payload');
            this.cancel('false');
            this.toastServ.presentToast(res?.message, 'success');
          }
        });
      }
    }
  }
  onaddNoteSelect(event: any) {
    this.isaddNote = event.detail.checked ? true : false;
    this.addMITStock.controls.notesCheck.setValue(this.isaddNote);
  }

  getNotes(mitId) {
    let notes: any;
    this.toolsApi.getMITNotes(mitId).subscribe((res1: any) => {
      if (res1.data.length > 0) {
        let i = res1.data.length - 1;
        notes = res1.data[i];
        this.notesSlug = notes?.slug;
        this.isNew = !notes?.description ? true : false;
        this.isaddNote = !!notes?.description ? true : false;
        this.addMITStock.patchValue({
          noteDes: notes?.description,
          notesCheck: this.isaddNote,
        });
      } else {
        this.isaddNote = false;
        this.notesSlug = '';
        this.isNew = true;
        this.addMITStock.patchValue({
          noteDes: '',
          notesCheck: this.isaddNote,
        });
      }
    });
  }
}
