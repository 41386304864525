<!-- <div class="drawer-header">
  <div class="header-container">
    <div class="back-to">
      <ion-buttons slot="end" *ngIf="showForm !== false && !editSlug">
        <ion-button (click)="cancel()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </div>
</div> -->
<ng-container *ngIf="!showForm">
  <div class="page-heading">
    <h2>{{ editSlug ? 'Edit' : 'Create' }} Notes</h2>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>
  <div class="form-container">
    <div class="form-container">
      <div class="edit-trade-note" *ngIf="editSlug">
        <div
          class="top-box-data"
          *ngIf="
            toolNotesData?.noteDetail?.type == 'weekly' || toolNotesData?.noteDetail?.type == 'long'
          "
        >
          <span class="green-lb">
            {{
              toolNotesData?.noteDetail?.type == 'weekly' ? 'Weekly Leg' : 'Protective Leg'
            }}</span
          >
          <div class="strip-line">
            <div class="text-data">
              Expiration:
              <span>{{ toolNotesData?.noteDetail?.expiration | date : 'MM/dd/yyyy' }}</span>
            </div>
            <div class="bullet-cirecl line"></div>
            <div class="text-data bullet">
              Strike:
              <span>${{ toolNotesData?.noteDetail?.strike }}</span>
            </div>
          </div>
        </div>
        <div class="top-box-data" *ngIf="toolNotesData?.noteDetail?.type == 'mit'">
          <span class="green-lb"> MIT Stock</span>
          <div class="strip-line">
            <div class="text-data">
              <span>{{ toolNotesData?.noteDetail?.mitId?.transactionType }}</span>
            </div>
            <div class="bullet-cirecl line"></div>
            <div class="text-data bullet">
              Shares:
              <span>{{ toolNotesData?.noteDetail?.mitId?.quantity }}</span>
            </div>
          </div>
        </div>
      </div>
      <form (ngSubmit)="onNotesCreateSubmit()" [formGroup]="toolNotesForm">
        <div class="form-group">
          <label style="font-size: 18px" for="notes">Note Description</label>
          <textarea
            class="note-description"
            formControlName="description"
            id="description"
            rows="4"
            cols="50"
          ></textarea>
        </div>
        <ion-button
          type="submit"
          [disabled]="toolNotesForm.invalid"
          color="primary"
          fill="solid"
          style="width: 100%"
        >
          {{ editSlug ? 'Update' : 'Create' }} Notes
        </ion-button>
      </form>
    </div>
  </div>
</ng-container>
