import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-stock-details',
  templateUrl: './stock-details.component.html',
  styleUrls: ['./stock-details.component.scss'],
})
export class StockDetailsComponent implements OnInit {
  lookupListVisibility: boolean = false;
  @Input() watchlistId;
  @Input() selectedSymbol: string[] = [];
  listOfLookup: any[] = [];
  searchResult: any[];
  someProperty: any;
  isLoading = true;
  public filterStockByName: string = null;
  public searchText: string = null;
  sortDirection = 'asc';
  stockDetails: any[];
  selectedStockDetails: any;
  selectedLookup: {
    name: string;
    symbol: string;
    exchange: string;
  } = {
    name: '',
    symbol: '',
    exchange: '',
  };
  buttonStateGroup = {
    is_watch_list: false,
    title: 'Add To Watchlist',
    icon: 'add-outline',
    isButtonActive: false,
    color: 'dark',
  };
  highlightedIndex: number = -1;
  public progress = 1;
  message: any = '';
  isShow: boolean = false;
  constructor(
    private toolsApi: ToolServiceService,
    private cdr: ChangeDetectorRef,
    public modalController: ModalController,
    private toastServ: ToastService
  ) {
    // setInterval(() => {
    //   this.progress += 0.01;
    //   // Reset the progress bar when it reaches 100%
    //   // to continuously show the demo
    //   if (this.progress > 1) {
    //     setTimeout(() => {
    //       this.progress = 0;
    //     }, 1000);
    //   }
    // }, 50);
    // this.reverseProgress();
  }
  reverseProgress() {
    let progress = this.progress;

    // Decrease the progress from 100% to 0%
    const interval = setInterval(() => {
      progress -= 0.01; // Decrease by small steps

      if (progress <= 0) {
        clearInterval(interval); // Stop once progress reaches 0
        progress = 0;
      }

      this.progress = progress;
      // Manually trigger change detection
      this.cdr.detectChanges();
    }, 50); // Adjust the speed by changing the interval time
  }
  ngOnInit() {
    this.stockDetails = [];
    this.isLoading = false;
    this.isShow = false;
    /* this.getWatchlistStockDetails(); */
  }

  onSearchLookup(event: string) {
    this.lookupListVisibility = true;
    const trimmedSearchValue = event;
    if (trimmedSearchValue.length > 0) {
      const exactMatches = [];
      const otherMatches = [];
      for (const item of this.stockDetails) {
        if (item.name.includes(item?.subCategory)) {
          item.name = item?.name?.split(item?.subCategory)?.[0].trim();
        }
        const value = `${item.symbol} - ${item.name}`.toLowerCase();
        if (value.startsWith(trimmedSearchValue)) {
          exactMatches.push(item);
        } else if (value.includes(trimmedSearchValue)) {
          otherMatches.push(item);
        }
      }
      this.searchResult = [...exactMatches, ...otherMatches];
      this.highlightedIndex = -1;
    } else {
      this.lookupListVisibility = false;
      this.selectedLookup = {
        symbol: '',
        name: '',
        exchange: '',
      };
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    if (this.lookupListVisibility && this.searchResult.length > 0) {
      switch (event.key) {
        case 'ArrowDown':
          if (this.highlightedIndex < this.searchResult.length - 1) {
            this.highlightedIndex++;
          }
          event.preventDefault();
          break;
        case 'ArrowUp':
          if (this.highlightedIndex > 0) {
            this.highlightedIndex--;
          }
          event.preventDefault();
          break;
        case 'Enter':
          if (this.highlightedIndex >= 0) {
            this.addLookup(this.searchResult[this.highlightedIndex].symbol);
          } else if (this.searchResult.length > 0) {
            this.addLookup(this.searchResult[0].symbol);
          }
          event.preventDefault();
          break;
      }
    }
  }

  addLookup(symbol: string) {
    this.hideLookupList();
    this.onClearSearch();
    this.toolsApi.setContext('dashboard');
  }

  hideLookupList() {
    this.lookupListVisibility = false;
    this.selectedLookup = {
      name: '',
      symbol: '',
      exchange: '',
    };
  }

  onClearSearch() {
    this.searchText = null;
    this.stockDetails = [];
  }

  onSearchCategory(ev: CustomEvent, type: string) {
    this.searchText = type === 'filter' && ev.detail.value === 'All' ? null : ev.detail.value;
    if (this.searchText) {
      this.isLoading = true;
      this.getWatchlistStockDetails();
    } else {
      this.stockDetails = [];
    }
  }

  getWatchlistStockDetails() {
    if (this.filterStockByName) {
      this.searchText = this.filterStockByName;
    }
    if (this.searchText) {
      // this.toolsApi.getWatchListTrackerDetails(this.searchText).subscribe((response: any) => {
      this.toolsApi.getTickerSymbol(this.searchText).subscribe((response: any) => {
        this.isLoading = true;
        // response && response?.stocks
        if (response && response?.data) {
          this.stockDetails = response?.data;
          this.isLoading = false;
          this.onSearchLookup(this.searchText);
        } else {
          this.stockDetails = [];
        }
        this.isLoading = false;
      });
    } else {
      this.stockDetails = [];
      this.isLoading = false;
    }
  }

  sortDataPrice() {
    const sortedData = [...this.stockDetails].sort((a, b) => {
      if (this.sortDirection === 'asc') {
        return a.regularMarketPrice - b.regularMarketPrice;
      } else {
        return b.regularMarketPrice - a.regularMarketPrice;
      }
    });
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.stockDetails = sortedData;
  }

  sortDataChangePer() {
    const sortedData = [...this.stockDetails].sort((a, b) => {
      if (this.sortDirection === 'asc') {
        return a.regularMarketChangePercent - b.regularMarketChangePercent;
      } else {
        return b.regularMarketChangePercent - a.regularMarketChangePercent;
      }
    });
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.stockDetails = sortedData;
  }

  sortDataChange() {
    const sortedData = [...this.stockDetails].sort((a, b) => {
      if (this.sortDirection === 'asc') {
        return a.regularMarketChange - b.regularMarketChange;
      } else {
        return b.regularMarketChange - a.regularMarketChange;
      }
    });
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.stockDetails = sortedData;
  }

  close() {
    // window.location.reload();
    this.modalController.dismiss(null);
  }

  toggleWatchlist(row: any) {
    const payload = {
      name: '',
      symbol: row.symbol,
      watchListIds: [this.watchlistId],
    };
    const isSelected = this.selectedSymbol.includes(row.symbol);
    if (!row.isInWatchlist && !isSelected) {
      this.toolsApi.addWatchList(payload).subscribe((response: any) => {
        if (response && response.message) {
          this.isShow = true;
          this.message = row.symbol + ' was added to your watchlist';
          this.progress = 1;
          this.reverseProgress();
          // this.toastServ.presentToast(response.message, 'success');
          row.isInWatchlist = true;
          setTimeout(() => this.closeNotification(), 5000);
        }
      });
    } else if (row.isInWatchlist || isSelected) {
      this.toolsApi.removeFromWatchList(row.symbol).subscribe((response: any) => {
        if (response && response.message) {
          this.isShow = true;
          this.message = row.symbol + ' was removed from your watchlist.';
          this.progress = 1;
          this.reverseProgress();
          // this.toastServ.presentToast(response.message, 'success');
          row.isInWatchlist = false;
          this.selectedSymbol = this.selectedSymbol.filter((symbol) => symbol !== row.symbol);
          setTimeout(() => {
            if (this.progress === 0) {
              this.closeNotification();
            }
          }, 5000);
        }
      });
    }
  }
  closeNotification() {
    this.isShow = false;
  }
  getHeaderClassTodayChange({ row }): any {
    return {
      'custom-green': Number(row?.regularMarketChange) >= 0,
      'custom-red': Number(row?.regularMarketChange) < 0,
      'custom-black': Number(row?.regularMarketChange) === 0,
    };
  }

  getHeaderClassTodayChangePer({ row }): any {
    return {
      'custom-green': Number(row?.regularMarketChangePercent) >= 0,
      'custom-red': Number(row?.regularMarketChangePercent) < 0,
      'custom-black': Number(row?.regularMarketChangePercent) === 0,
    };
  }
}
